import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./DepartmentCarousal.css";
import { SendPostRequest } from "../../http";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Spinner from "../Spinner";

const DepartmentCarousel = ({ setIsdata }) => {
  const [featuredService, setFeaturedService] = useState([]);
  const sitedata = useSelector((store) => store.global.loginData);

  useEffect(() => {
    document.title = "Home";
    getFeaturedService();
  }, []);

  const getFeaturedService = async () => {
    const response = await SendPostRequest("services/featured_services");
    // if (response?.data.length < 5) {
    //   setIsdata(true)
    // }
    if (response?.data?.length < 5 && sitedata?.site_type === "hospitals") {
      setIsdata(true);
    }
    setFeaturedService(response?.data);
  };

  const settings = {
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="container department-carousal">
      <div className="featured_services_heading_wrapper pt-4 mb-4 mb-lg-5">
        <h2 className="cs_section_title cs_fs_65 m-0 ">Featured Services</h2>
        <Link to="/services">
          <button className="featured_services_view_btn">View All</button>
        </Link>
      </div>

      <div className="featured_services_desktop">
        <div className="row ">
          {featuredService?.length === 0 ? (
            <div
              style={{ marginBottom: "100px" }}
              className="d-flex align-items-center justify-content-center "
            >
              <Spinner />
            </div>
          ) : (
            featuredService?.length > 0 &&
            featuredService?.map((service, index) => (
              <div key={index} className="col-md-6 col-xl-4">
                <div className="cs_iconbox cs_style_4 featured-card-wrapper ">
                  <h2 className="service-card-title p-3 m-0">
                    {service.service_title}
                  </h2>
                  <div
                    className="cs_iconbox_icon cs_accent_bg e cs_center services-img-wrapper mb-1"
                    style={{
                      backgroundImage: `url(${service?.service_image_url})`,
                    }}
                  ></div>

                  <div className="p-3 ">
                    <div
                      className="featured-card-wrapper-text"
                      dangerouslySetInnerHTML={{
                        __html:
                          service.service_description.slice(0, 170) + "... ",
                      }}
                    />
                    <Link
                      to={`/service/${service?.uid}/${service?.service_title
                        .replaceAll(" ", "-")
                        .replaceAll("/", "-")}`}
                    >
                      <button className="service-read-more">Read More</button>
                    </Link>
                  </div>
                  {service.link && (
                    <a href={service.link} className="cs_iconbox_btn cs_center">
                      <img src="/assets/img/icons/arrow_white.svg" alt="Icon" />
                      <img src="/assets/img/icons/arrow_white.svg" alt="Icon" />
                    </a>
                  )}
                </div>
              </div>
            ))
          )}
        </div>
      </div>

      <div className="featured_services_mobile">
        <Slider {...settings}>
          {featuredService?.length > 0 &&
            featuredService?.map((service, index) => (
              <div key={index} className="cs_slide m-3">
                <div className="cs_iconbox cs_style_4 featured-card-wrapper ">
                  <h2 className="service-card-title p-3 m-0">
                    {service.service_title}
                  </h2>
                  <div
                    className="cs_iconbox_icon cs_accent_bg e cs_center services-img-wrapper mb-1"
                    style={{
                      backgroundImage: `url(${service?.service_image_url})`,
                    }}
                  ></div>

                  <div className="p-3">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: service.service_description.slice(0, 110),
                      }}
                    />
                    <Link
                      to={`/service/${service?.uid}/${service?.service_title
                        .replaceAll(" ", "-")
                        .replaceAll("/", "-")}`}
                    >
                      <button className="service-read-more">Read More</button>
                    </Link>
                  </div>
                  {service.link && (
                    <a href={service.link} className="cs_iconbox_btn cs_center">
                      <img src="/assets/img/icons/arrow_white.svg" alt="Icon" />
                      <img src="/assets/img/icons/arrow_white.svg" alt="Icon" />
                    </a>
                  )}
                </div>
              </div>
            ))}
        </Slider>
      </div>
    </div>
  );
};

export default DepartmentCarousel;
