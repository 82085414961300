import zIndex from "@mui/material/styles/zIndex";
import React, { useState, useEffect } from "react";
import { FaArrowUp } from "react-icons/fa";

const ScrollToTop = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const styles = {
    button: {
      position: "fixed",
      bottom: "18px",
      right: "145px",
      // backgroundColor: "#ffc400",
      // backgroundColor: `var(--primary-color)`,

      // color: "#fff",

      backgroundColor: isHovered
        ? "var(--secondary-color)"
        : "var(--primary-color)",
      color: isHovered ? "var(--primary-color)" : "var(--secondary-color) ",
      border: "none",
      borderRadius: "50%",
      width: "40px",
      height: "40px",
      cursor: "pointer",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex:"1",
    },
  };

  return (
    <div className="scroll-to-top">
      {isVisible && (
        <button
          onClick={scrollToTop}
          style={styles.button}
          onMouseEnter={() => setIsHovered(true)} 
          onMouseLeave={() => setIsHovered(false)}
        >
          <FaArrowUp size={16} />
        </button>
      )}
    </div>
  );
};

export default ScrollToTop;
