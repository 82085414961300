import React from "react";
import { useSelector } from "react-redux";
import Appointment from "./Appointment";
import AppointmentDoctor from "./AppointmentDoctor";

function AppointmentContainer() {
  const sitedata = useSelector((store) => store.global.loginData);
  return (
    <div>{sitedata?.site_type === "hospitals" ? <Appointment /> : <AppointmentDoctor />}</div>
  );
}

export default AppointmentContainer;
