import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { capitalize } from "@mui/material";
import { SendPostRequest } from "../../http";
import Captcha from "../Captcha/Captcha";
import HeaderWrapper from "../Header/HeaderWrapper";
import FooterWrapper from "../Footer/FooterWrapper";

function Appointment() {
  const sitedata = useSelector((store) => store.global.loginData);
  const patientLogin = useSelector((state) => state.patient.patientLogin);
  const todayDate = new Date().toISOString().split("T")[0];

  const [inputValues, setInputValues] = useState({});
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [doctorSelected, setDoctorSelected] = useState(null);
  const [formateDoctor, setFormateDoctor] = useState([]);
  const [allDoctors, setAllDoctors] = useState([]);

  const [relation, setRelation] = useState(null);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [selectedDoctorId, setSelectedDoctorId] = useState(null);
  const [doctorOption, setDoctorOption] = useState([]);
  // const [gender, setGender] = useState(null);
  const [shift, setShift] = useState(null);

  const [hospitalSlots, setHospitalSlots] = useState([]);
  const [displayedValue, setDisplayedValue] = useState(null);

  useEffect(() => {
    if (patientLogin) {
      setInputValues({
        patient_name: patientLogin?.fullname,
        email: patientLogin?.email,
        contact_number: patientLogin?.contact_num,
      });
    }
    const title = sitedata?.site_title || "";
    document.title = capitalize(title) + " :: Online Appointments - ClinTa";
    window.scrollTo(0, 0);
    handleRefresh();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await SendPostRequest("doctors/list");
        setAllDoctors(response.data);
        const formattedDoctors = response.data
          .filter((doctor) => doctor.speciality)
          .map((doctor) => ({
            value: doctor.speciality,
            label: doctor.speciality,
          }));

        const doctorsWithOptions = [
          { value: "All", label: "All" },
          { value: "E-Health", label: "E-Health" },
          ...formattedDoctors,
        ];

        setFormateDoctor(doctorsWithOptions);
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const getHospitalSlots = async () => {
      const date = inputValues.appointment_date;
      const type = sitedata.site_type;
      let allInputs;

      try {
        allInputs = { type, doct_uid: selectedDoctorId, date };
        if (date) {
          const res = await SendPostRequest("hospitals/slots", allInputs);
          setHospitalSlots(res.data);
        }
      } catch (error) {
        console.error("Error fetching doctor's slots:", error);
      }
    };
    getHospitalSlots();
  }, [inputValues.appointment_date]);

  const handleDoctorsChange = (selectedOption) => {
    setDoctorSelected(selectedOption.value);

    let filteredDoctors;

    if (selectedOption.value === "All") {
      filteredDoctors = allDoctors;
    } else if (selectedOption.value === "E-Health") {
      filteredDoctors = allDoctors.filter(
        (doctorData) => doctorData.telemedicine_id === 1
      );
    } else {
      filteredDoctors = allDoctors.filter(
        (doctorData) => doctorData.speciality === selectedOption.value
      );
    }
    const formattedDoctors = filteredDoctors.map((doctor) => ({
      value: doctor.full_name,
      label: doctor.full_name,
      id: doctor.uid,
    }));
    setDoctorOption(formattedDoctors);
  };
  const reasonOptions = [
    { value: "son-of", label: "Son Of" },
    { value: "daughter-of", label: "Daughter Of" },
    { value: "father-of", label: "Father Of" },
    { value: "mother-of", label: "Mother Of" },
    { value: "husband-of", label: "Husband Of" },
    { value: "wife-of", label: "Wife Of" },
  ];
  // const genderOptions = [
  //   { value: "male", label: "Male" },
  //   { value: "female", label: "Female" },
  //   { value: "other", label: "Other" },
  // ];

  const [shiftOptions, setShiftOptions] = useState([]);

  useEffect(() => {
    const newShiftOptions = [];

    if (
      Object.keys(hospitalSlots).length === 0 &&
      inputValues.appointment_date
    ) {
      newShiftOptions.push({ value: "evening", label: "Evening" });
      newShiftOptions.push({ value: "morning", label: "Morning" });
    } else {
      for (const key in hospitalSlots) {
        if (hospitalSlots.hasOwnProperty(key)) {
          newShiftOptions.push({ value: key, label: hospitalSlots[key] });
        }
      }
    }

    setShiftOptions(newShiftOptions);
    setDisplayedValue(null);
  }, [hospitalSlots]);

  const handleReasonChange = (selectedOption) => {
    setRelation(selectedOption.value);
  };
  const handleDoctorChange = (selectedOption) => {
    setSelectedDoctor(selectedOption.value);
    setSelectedDoctorId(selectedOption.id);
  };
  // const handleGenderChange = (selectedOption) => {
  //   setGender(selectedOption.value);
  // };
  const handleShiftChange = (selectedOption) => {
    setShift(selectedOption.value);
    setDisplayedValue(selectedOption?.label);
  };

  const [captcha, setCaptcha] = useState("");
  const [input_captcha, setInputCaptcha] = useState("");
  const [check_captcha, setCheckCaptcha] = useState("");
  const [appointmentisLoading, setAppointmentisLoading] = useState(false);

  const formatDate = (inputDate) => {
    const dateObject = new Date(inputDate);

    const year = dateObject.getFullYear();
    const month = dateObject.getMonth() + 1;
    const day = dateObject.getDate();
    const formattedDate = `${year}-${month < 10 ? "0" + month : month}-${
      day < 10 ? "0" + day : day
    }`;

    return formattedDate;
  };

  const handleContactNumber = (e) => {
    let val = e.target.value;
    if (val.length <= 14) {
      setInputValues((prevValues) => ({
        ...prevValues,
        contact_number: val,
      }));
    }
    return;
  };

  const handleAllInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "appointment_date") {
      const formattedDate = formatDate(value);
      setInputValues((prevValues) => ({
        ...prevValues,
        appointment_date: formattedDate,
      }));
    } else {
      setInputValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  const formSubmit = async (e) => {
    e.preventDefault();
    setAppointmentisLoading(true);

    if (!inputValues.patient_name) {
      toast.error("Enter patient name");
      setAppointmentisLoading(false);
      return;
    // } 
    // else if (!gender) {
    //   toast.error("Select Gender");
    //   setAppointmentisLoading(false);
    //   return;
    } else if (!inputValues.contact_number) {
      toast.error("Enter Number");
      setAppointmentisLoading(false);
      return;
    } else if (
      inputValues.contact_number.length > 14 ||
      inputValues.contact_number.length < 10
    ) {
      toast.error("Invalid Number");
      setAppointmentisLoading(false);
      return;
    } else if (!doctorSelected) {
      toast.error("Select Speciality");
      setAppointmentisLoading(false);
      return;
    } else if (!selectedDoctor) {
      toast.error("Select Doctor");
      setAppointmentisLoading(false);
      return;
    } else if (!inputValues.appointment_date) {
      toast.error("Enter Date");
      setAppointmentisLoading(false);
      return;
    } else if (!shift) {
      toast.error("Select Your Shift");
      setAppointmentisLoading(false);
      return;
    } else if (captcha === input_captcha) {
      setCheckCaptcha("Captcha Verified");
    } else {
      setCheckCaptcha("Captcha failed");
      setAppointmentisLoading(false);
    }

    if (captcha === input_captcha) {
      try {
        const myallInputs = {
          ...inputValues,
          relation,
          doctor_id: selectedDoctorId,
          // gender,
          shift,
        };

        const response = await SendPostRequest(
          "doctors/appointment_form",
          myallInputs
        );
        if (response.status === "success") {
          setInputValues({
            patient_name: patientLogin ? patientLogin.fullname : "",
            email: patientLogin ? patientLogin.email : "",
            contact_number: patientLogin ? patientLogin.contact_num : "",
            age: "",
            relation_name: "",
            appointment_date: "",
            remarks: "",
          });
          setDisplayedValue(null);
          setInputCaptcha("");
          setCheckCaptcha("");
          setSelectedDoctorId([]);
          setSelectedDoctor({ value: "" });
          setRelation({ value: "" });
          setShift({ value: "" });
          // setGender({ value: "" });
          setDoctorOption([]);
          setDoctorSelected({ value: "" });
          handleRefresh();
        }

        toast.success(response.message);

        setAppointmentisLoading(false);
      } catch (e) {
        toast.error("Something Went Wrong");
        setAppointmentisLoading(false);
      }
    } else {
      setCheckCaptcha("Captcha failed");
      toast.error("Captcha verification failed");
      setAppointmentisLoading(false);
    }
  };

  const textColorClass =
    check_captcha === "Captcha Verified" ? "text-success" : "text-danger";

  const handleInputChange = (e) => {
    setInputCaptcha(e.target.value);
  };

  const handleRefresh = () => {
    const newCaptcha = generateRandomCaptcha();
    setCaptcha(newCaptcha);
  };

  const generateRandomCaptcha = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const captchaLength = 6;
    let newCaptcha = "";

    for (let i = 0; i < captchaLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      newCaptcha += characters.charAt(randomIndex);
    }

    return newCaptcha;
  };


  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      formSubmit(e);
    }
  };
  return (
    <div className="page-wrapper-inner">
      <div class="page-wrapper-inner">
        <HeaderWrapper />
        <div class="page-title-wrap typo-white">
          <div
            class="page-title-wrap-inner section-bg-img"
            style={{
              backgroundImage: "url(/assets/images/bg/page-title-bg.jpg)",
            }}
          >
            <span class="black-overlay"></span>
            <div class="container">
              <div class="row text-left">
                <div class="col-md-12">
                  <div class="page-title-inner">
                    <h1 class="page-title mb-0">Appointment</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container mt-4">
          <div className="contact-form" data-animation="fadeInLeft" onKeyDown={handleKeyDown}>
            <div className="row py-4">
              <div className="col-lg-6 mb-3">
                <label className="form-label">
                  Patient Name <span className="asterisk">*</span>
                </label>
                <input
                  name="patient_name"
                  value={inputValues.patient_name}
                  type="text"
                  className="input-name form-control"
                  placeholder="Enter Name"
                  onChange={handleAllInputChange}
                />
                <div className="cs_height_42 cs_height_xl_25"></div>
              </div>
              {/* <div className="col-lg-6 mb-3">
              <label className="form-label">Please Select Relation</label>
              <Select
                className="appointment_form_select"
                name="relation_select"
                value={relation?.value}
                options={reasonOptions}
                onChange={handleReasonChange}
                placeholder="Select...."
              />

              <div className="cs_height_42 cs_height_xl_25"></div>
            </div>
            <div className="col-lg-6 mb-3">
              <label className="form-label">Relation Name</label>
              <input
                name="relation_name"
                value={inputValues.relation_name}
                type="text"
                className="input-name form-control"
                placeholder="Enter your relation"
                onChange={handleAllInputChange}
              />
              <div className="cs_height_42 cs_height_xl_25"></div>
            </div>

            <div className="col-lg-6 mb-3">
              <label className="form-label">
                Please Select Gender <span className="asterisk">*</span>
              </label>
              <Select
                className="appointment_form_select"
                value={gender?.value}
                options={genderOptions}
                onChange={handleGenderChange}
                placeholder=" Please Select Gender"
              />

              <div className="cs_height_42 cs_height_xl_25"></div>
            </div> */}
              <div className="col-lg-6 mb-3">
                <label className="form-label">
                  Contact Number <span className="asterisk">*</span>
                </label>
                <input
                  name="contact_number"
                  value={inputValues.contact_number}
                  type="text"
                  className="input-name form-control"
                  placeholder="Enter Contact Number"
                  onChange={handleContactNumber}
                  maxLength={14}
                  minLength={10}
                />
                <div className="cs_height_42 cs_height_xl_25"></div>
              </div>
              <div className="col-lg-6 mb-3">
                <label className="form-label">Email</label>
                <input
                  name="email"
                  value={inputValues.email}
                  type="email"
                  className="input-name form-control"
                  placeholder="Enter your email"
                  onChange={handleAllInputChange}
                />
                <div className="cs_height_42 cs_height_xl_25"></div>
              </div>

              <div className="col-lg-6 mb-3">
                <label className="form-label">
                  Please Select Speciality <span className="asterisk">*</span>
                </label>
                <Select
                  className="appointment_form_select"
                  value={doctorSelected?.value}
                  onChange={handleDoctorsChange}
                  options={formateDoctor}
                  placeholder="Select speciality"
                />

                <div className="cs_height_42 cs_height_xl_25"></div>
              </div>
              <div className="col-lg-6 mb-3">
                <label className="form-label">
                  Please Select Doctor <span className="asterisk">*</span>
                </label>
                <Select
                  className="appointment_form_select"
                  value={selectedDoctor?.value}
                  options={doctorOption}
                  onChange={handleDoctorChange}
                  placeholder="Select a doctor"
                />
                <div className="cs_height_42 cs_height_xl_25"></div>
              </div>

              <div className="col-lg-6 mb-3">
                <label className="form-label">
                  Preferred Date <span className="asterisk">*</span>{" "}
                  {!selectedDoctorId && <span>Please Select Doctor First</span>}
                </label>
                <div className="cs_with_icon_input">
                  <input
                    disabled={!selectedDoctorId}
                    name="appointment_date"
                    value={inputValues.appointment_date}
                    type="date"
                    className="appointment_date_input"
                    id="datepicker"
                    placeholder="August 24, 2023"
                    onChange={handleAllInputChange}
                    min={todayDate}
                  />
                </div>
                <div className="cs_height_42 cs_height_xl_25"></div>
              </div>

              <div className="col-lg-6 mb-3">
                <label className="form-label">
                  Preferred Time <span className="asterisk">*</span>
                </label>
                <Select
                  className="appointment_form_select"
                  value={
                    displayedValue !== null
                      ? { value: hospitalSlots?.value, label: displayedValue }
                      : null
                  }
                  options={shiftOptions}
                  onChange={handleShiftChange}
                  placeholder={
                    inputValues?.appointment_date
                      ? "Select Shift"
                      : "Please Select Date First"
                  }
                />

                <div className="cs_height_42 cs_height_xl_25"></div>
              </div>

              {/* <div className="col-lg-6 mb-3">
              <label className="form-label">Age</label>
              <input
                name="age"
                value={inputValues.age}
                type="number"
                className="input-name form-control"
                placeholder="Enter your age"
                onChange={handleAllInputChange}
              />
              <div className="cs_height_42 cs_height_xl_25"></div>
            </div> */}

              <div className="col-lg-6 appointment_form_capcha mb-3">
                <label className="form-label">Captcha</label>
                <Captcha
                  captcha={captcha}
                  inputCaptcha={input_captcha}
                  textColorClass={textColorClass}
                  checkCaptcha={check_captcha}
                  handleInputChange={handleInputChange}
                />
              </div>

              <div className="col-lg-12 appointment-textarea mb-3">
                <label className="form-label">Remarks</label>
                <textarea
                  rows={5}
                  name="remarks"
                  value={inputValues.remarks}
                  type="number"
                  className="input-name form-control"
                  placeholder="Write Your remarks here"
                  onChange={handleAllInputChange}
                ></textarea>
                <div className="cs_height_42 cs_height_xl_25"></div>
              </div>

              <div className="col-lg-12 mb-3 mb-3">
                <div className="cs_height_18"></div>
                <div className="d-flex justify-content-end">
                  <button
                    type="submit"
                    className="btn btn-default"
                    style={{ width: "100px" }}
                    onClick={formSubmit}
                    disabled={appointmentisLoading}
                  >
                    <span>
                      {appointmentisLoading ? "Please Wait" : "Submit "}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <FooterWrapper />
      </div>
    </div>
  );
}

export default Appointment;
