import React from "react";
import { useSelector } from "react-redux";
import AppointmentDoctor from "./AppointmentDoctor";
import Appointments from "./Appointments";

function AppointmentContainer() {
  const sitedata = useSelector((store) => store.global.loginData);
  return (
    <div>{sitedata?.site_type === "hospitals" ? <Appointments /> : <AppointmentDoctor />}</div>
  );
}

export default AppointmentContainer;
