import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { SendPostRequest } from "../http";
import { Link } from "react-router-dom";
import createSlug from "../utils/CreateSlug";
import Spinner from "./Spinner";

const teamData = [
  {
    id: 1,
    name: "Dr.Oviya Ben",
    designation: "Dentist",
    image: "/assets/images/team/1.jpg",
    social: {
      facebook: "#",
      twitter: "#",
      instagram: "#",
      linkedin: "#",
    },
  },
  {
    id: 2,
    name: "Dr.Sno White",
    designation: "Psychologist",
    image: "/assets/images/team/medical/2.jpg",
    social: {
      facebook: "#",
      twitter: "#",
      instagram: "#",
      linkedin: "#",
    },
  },
  {
    id: 3,
    name: "Dr.Ken Morgan",
    designation: "Cardiologist",
    image: "/assets/images/team/medical/3.jpg",
    social: {
      facebook: "#",
      twitter: "#",
      instagram: "#",
      linkedin: "#",
    },
  },
  {
    id: 4,
    name: "Dr.Aimee Devlin",
    designation: "Surgeon",
    image: "/assets/images/team/medical/4.jpg",
    social: {
      facebook: "#",
      twitter: "#",
      instagram: "#",
      linkedin: "#",
    },
  },
  {
    id: 5,
    name: "Dr.Rashed Chotta",
    designation: "Ophthalmologist",
    image: "/assets/images/team/medical/5.jpg",
    social: {
      facebook: "#",
      twitter: "#",
      instagram: "#",
      linkedin: "#",
    },
  },
];

function MeetDoctors() {
  const [doctorsData, setDoctorsData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getDoctorsData();
  }, []);

  const getDoctorsData = async () => {
    try {
      const response = await SendPostRequest("doctors/list");
      setDoctorsData(response?.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching doctors data:", error);
    }
  };

  const owlOptions = {
    loop: true,
    nav: false,
    dots: false,
    autoplay: true,
    autoplayTimeout: 2500,
    autoplayHoverPause: true,
    smartSpeed: 1000,
    margin: 30,
    responsive: {
      0: { items: 1 },
      600: { items: 2 },
      1000: { items: 4 },
    },
  };

  return (
    <>
      {loading ? (
        <div
          style={{ marginBottom: "100px" }}
          className="d-flex align-items-center justify-content-center "
        >
          <Spinner />
        </div>
      ) : doctorsData?.length > 0 ? (
        <section
          id="team-section"
          className="team-section doctor-team-bg-overlay section-bg-img py-5  mb-0"
          // style={{ backgroundImage: `url('/assets/images/bg/bg-1.jpg')` }}
          style={{
            backgroundImage: `url('/assets/images/bg/meet-doctor-bg.jpg')`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="offset-md-2 col-md-8">
                <div className="title-wrap text-center">
                  <div className="section-title margin-bottom-60">
                    <h2 className="title mb-0 typo-white text-uppercase">
                      Meet Our <span className="theme-color">Specialist</span>
                    </h2>
                    <span className="section-border-bottom center"></span>
                  </div>
                </div>
              </div>
              <OwlCarousel
                className="owl-theme team-main-wrapper typo-white"
                {...owlOptions}
              >
                {doctorsData?.map((data, index) => (
                  <div className="item" key={index}>
                    <div className="team-style-1">
                      <div className="team-inner margin-bottom-20">
                        <div className="team-thumb mb-0 relative">
                          {/* <img
                            src={data?.profile}
                            className="img-fluid thumb w-100"
                            style={{ height: "300px" }}
                          /> */}
                          <div
                            // className="img-fluid thumb w-100"
                            style={{
                              height: "300px",
                              width: "100%",
                              backgroundImage: `url(${data?.profile})`,
                              backgroundSize: "cover",
                              backgroundPosition: "top center",
                              backgroundRepeat: "no-repeat",
                            }}
                          />
                        </div>
                        <div className="team-details text-center pad-lr-30 pad-top-30">
                          <div className="team-name">
                            <h3
                              className="typo-white mb-0"
                              style={{ fontSize: "18px" }}
                            >
                              <Link
                                to={`/doctor/${data?.uid}/${createSlug(
                                  data?.full_name
                                )}`}
                                className="client-name"
                              >
                                {data?.full_name}
                              </Link>
                            </h3>
                          </div>
                          <div className="team-designation mb-2 theme-color meet-doctor-department">
                            <p className="mb-0">{data?.department}</p>
                          </div>
                          {/* <div className="team-social social-icons typo-white">
                          <a href="#">
                            <span className="ti-facebook"></span>
                          </a>
                          <a href="#">
                            <span className="ti-twitter"></span>
                          </a>
                          <a href="#">
                            <span className="ti-instagram"></span>
                          </a>
                          <a href="#">
                            <span className="ti-linkedin"></span>
                          </a>
                        </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
}

export default MeetDoctors;
