import React, { useState } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
// import "./DashboardTabs.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  setPatientLogout,
  setPatientProfile,
} from "../../store/global/actions";

import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";

function DropDownTabs({ onTabSelect, setFruitId, setTotkeyId }) {
  import("./DashboardTabs.css").then(() => {});
  const [openDropdown, setOpenDropdown] = useState(null);
  const [selectedTab, setSelectedTab] = useState("Dashboard");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userPatient = useSelector((store) => store.global.patientLogin);
  const my_account = useSelector((store) => store.global.loginData.my_account);

  const handleMouseEnter = (dropdownId) => {
    setOpenDropdown(dropdownId);
  };

  const handleMouseLeave = () => {
    setOpenDropdown(null);
  };

  const handleNavItemClicked = (heading) => {
    setFruitId("");
    setTotkeyId("");
    onTabSelect(heading);
    setOpenDropdown(null);
    setSelectedTab(heading);
    setExpanded(false);

    // Close the dropdown when a tab is clicked
  };

  const logout = () => {
    dispatch(setPatientLogout());
    dispatch(setPatientProfile(null));
    navigate("/patient");
    setExpanded(false);
  };

  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="DashboardTabs">
      {/* <Navbar
        className="p-0"
        bg=""
        expand="md"
        variant="dark"
        expanded={expanded}
        onToggle={handleToggle}
      >
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="">
            <Nav.Link
              // href="#"
              className={selectedTab === "Dashboard" ? "active" : ""}
              onClick={() => handleNavItemClicked("Dashboard")}
            >
              DashBoard
            </Nav.Link>

            {my_account.prescriptions && my_account.labreports && (
              <NavDropdown
                title="Health Records"
                id="more-items"
                onMouseEnter={() => handleMouseEnter("healthRecords")}
                onMouseLeave={handleMouseLeave}
                show={openDropdown === "healthRecords"}
              >
                <NavDropdown.Item
                  className={selectedTab === "Prescripition" ? "active" : ""}
                  onClick={() => handleNavItemClicked("Prescripition")}
                >
                  Prescripitions
                </NavDropdown.Item>
                <NavDropdown.Item
                  className={selectedTab === "Investigation" ? "active" : ""}
                  onClick={() => handleNavItemClicked("Investigation")}
                >
                  Investigation (Test)
                </NavDropdown.Item>
              </NavDropdown>
            )}

            <NavDropdown title="Diet" id="treeview-menu" className="m-0">
              <NavDropdown
                title="Diet Facts"
                id="submenu-2"
                className="submenu"
                menuAlign="right"
              >
                <NavDropdown.Item
                  className={selectedTab === "fruits" ? "active" : ""}
                  onClick={() => handleNavItemClicked("fruits")}
                >
                  Fruits
                </NavDropdown.Item>
                <NavDropdown.Item
                  className={selectedTab === "vegetables" ? "active" : ""}
                  onClick={() => handleNavItemClicked("vegetables")}
                >
                  Vegetable
                </NavDropdown.Item>
                <NavDropdown.Item
                  className={selectedTab === "pulses-grains" ? "active" : ""}
                  onClick={() => handleNavItemClicked("pulses-grains")}
                >
                  Pulses & Grains
                </NavDropdown.Item>
                <NavDropdown.Item
                  className={selectedTab === "dry-fruites" ? "active" : ""}
                  onClick={() => handleNavItemClicked("dry-fruites")}
                >
                  Dry Fruits & Edible Seeds
                </NavDropdown.Item>
                <NavDropdown.Item
                  className={selectedTab === "miscellaneous" ? "active" : ""}
                  onClick={() => handleNavItemClicked("miscellaneous")}
                >
                  Miscellaneous
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown.Item
                className={
                  selectedTab === "Comparative Nutrients" ? "active" : ""
                }
                onClick={() => handleNavItemClicked("Comparative Nutrients")}
              >
                Comparative Nutrients
              </NavDropdown.Item>
            </NavDropdown>

            <Nav.Link
              className={selectedTab === "Calculator" ? "active" : ""}
              onClick={() => handleNavItemClicked("Calculator")}
            >
              Calculators
            </Nav.Link>
            <Nav.Link
              className={selectedTab === "Appointment" ? "active" : ""}
              onClick={() => handleNavItemClicked("Appointment")}
            >
              Appointment
            </Nav.Link>
            <Nav.Link
              className={selectedTab === "E-Appointment" ? "active" : ""}
              onClick={() => handleNavItemClicked("E-Appointment")}
            >
              E-Appointment
            </Nav.Link>
            <Nav.Link
              className={selectedTab === "Totkey" ? "active" : ""}
              onClick={() => handleNavItemClicked("Totkey")}
            >
              Totkey
            </Nav.Link>

            <Nav.Link
              className={selectedTab === "Logout" ? "active logout " : "logout"}
              onClick={logout}
            >
              Logout
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar> */}
      <Sidebar>
        <Menu>
          <MenuItem
            className={selectedTab === "Dashboard" ? "active" : ""}
            onClick={() => handleNavItemClicked("Dashboard")}
          >
            DashBoard
          </MenuItem>

          <SubMenu label="Health Records">
            {my_account?.prescriptions && my_account?.labreports && (
              <>
                <MenuItem
                  className={selectedTab === "RegMembers" ? "active" : ""}
                  onClick={() => handleNavItemClicked("RegMembers")}
                >
                  Registered Members
                </MenuItem>
                <MenuItem
                  className={selectedTab === "Prescripition" ? "active" : ""}
                  onClick={() => handleNavItemClicked("Prescripition")}
                >
                  Prescripition
                </MenuItem>

                <MenuItem
                  className={selectedTab === "Investigation" ? "active" : ""}
                  onClick={() => handleNavItemClicked("Investigation")}
                >
                  Investigation
                </MenuItem>
              </>
            )}
            <MenuItem
              className={selectedTab === "Weight_managment" ? "active" : ""}
              onClick={() => handleNavItemClicked("Weight_managment")}
            >
              Weight Managment
            </MenuItem>
            <MenuItem
              className={selectedTab === "Blood_glucose" ? "active" : ""}
              onClick={() => handleNavItemClicked("Blood_glucose")}
            >
              Blood Glucose
            </MenuItem>
            <MenuItem
              className={selectedTab === "Blood_pressure" ? "active" : ""}
              onClick={() => handleNavItemClicked("Blood_pressure")}
            >
              Blood Pressure
            </MenuItem>
          </SubMenu>

          {/* {my_account.prescriptions && my_account.labreports && (
              <NavDropdown
                title="Health Records"
                id="more-items"
                onMouseEnter={() => handleMouseEnter("healthRecords")}
                onMouseLeave={handleMouseLeave}
                show={openDropdown === "healthRecords"}
              >
                <NavDropdown.Item
                  className={selectedTab === "Prescripition" ? "active" : ""}
                  onClick={() => handleNavItemClicked("Prescripition")}
                >
                  Prescripitions
                </NavDropdown.Item>
                <NavDropdown.Item
                  className={selectedTab === "Investigation" ? "active" : ""}
                  onClick={() => handleNavItemClicked("Investigation")}
                >
                  Investigation (Test)
                </NavDropdown.Item>
              </NavDropdown>
            )} */}

          <SubMenu label="Diet">
            <SubMenu label="Diet Facts">
              <MenuItem
                className={selectedTab === "fruits" ? "active" : ""}
                onClick={() => handleNavItemClicked("fruits")}
              >
                Fruits{" "}
              </MenuItem>
              <MenuItem
                className={selectedTab === "vegetables" ? "active" : ""}
                onClick={() => handleNavItemClicked("vegetables")}
              >
                Vegetable{" "}
              </MenuItem>
              <MenuItem
                className={selectedTab === "pulses-grains" ? "active" : ""}
                onClick={() => handleNavItemClicked("pulses-grains")}
              >
                Pulses & Grains{" "}
              </MenuItem>
              <MenuItem
                className={selectedTab === "dry-fruites" ? "active" : ""}
                onClick={() => handleNavItemClicked("dry-fruites")}
              >
                Dry Fruits & Edible Seeds{" "}
              </MenuItem>
              <MenuItem
                className={selectedTab === "miscellaneous" ? "active" : ""}
                onClick={() => handleNavItemClicked("miscellaneous")}
              >
                Miscellaneous{" "}
              </MenuItem>
            </SubMenu>
            <MenuItem
              className={
                selectedTab === "Comparative Nutrients" ? "active" : ""
              }
              onClick={() => handleNavItemClicked("Comparative Nutrients")}
            >
              Comparative Nutrients{" "}
            </MenuItem>
          </SubMenu>
          <MenuItem
            className={selectedTab === "Calculator" ? "active" : ""}
            onClick={() => handleNavItemClicked("Calculator")}
          >
            Calculators
          </MenuItem>

          <MenuItem
            className={selectedTab === "Appointment" ? "active" : ""}
            onClick={() => handleNavItemClicked("Appointment")}
          >
            {" "}
            Appointment{" "}
          </MenuItem>
          {/* <MenuItem
            className={selectedTab === "E-Appointment" ? "active" : ""}
            onClick={() => handleNavItemClicked("E-Appointment")}
          >
            {" "}
            E-Appointment{" "}
          </MenuItem> */}
          <MenuItem
            className={selectedTab === "Totkey" ? "active" : ""}
            onClick={() => handleNavItemClicked("Totkey")}
          >
            {" "}
            Totkey{" "}
          </MenuItem>
          <MenuItem
            className={selectedTab === "Logout" ? "active logout " : "logout"}
            onClick={logout}
          >
            {" "}
            Logout{" "}
          </MenuItem>
        </Menu>
      </Sidebar>
      ;
    </div>
  );
}

export default DropDownTabs;
