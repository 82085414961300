import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { capitalize } from "@mui/material";
import { SendPostRequest } from "../../http";
import HeaderWrapper from "../Header/HeaderWrapper";
import FooterWrapper from "../Footer/FooterWrapper";
import { toast } from "react-toastify";

function FirstContactus() {
  const [contactData, setContactData] = useState([]);
  const sitedata = useSelector((store) => store.global.loginData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formRef = useRef(null);

  const [contactForm, setContactForm] = useState({
    fullname: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!contactForm.fullname) {
      toast.error("InComplete Form");
      return;
    } else if (!contactForm.email) {
      toast.error("InComplete Form");
      return;
    } else if (!contactForm.phone) {
      toast.error("InComplete Form");
      return;
    } else if (
      contactForm?.phone?.length > 14 ||
      contactForm?.phone?.length < 10
    ) {
      toast.error("Invalid Contact Number");
      return;
    } else if (contactForm?.message === "") {
      toast.error("InComplete Form");
      return;
    }
    try {
      setIsSubmitting(true);
      const response = await SendPostRequest("contact-us-form", contactForm);
      formRef.current.reset();
      if (response.status === "success") {
        setContactForm({
          fullname: "",
          email: "",
          phone: "",
          message: "",
        });
        toast.success(response?.message);
      } else {
        let errorMessage = response?.message || "An error occurred";
        toast.error(`Error: ${errorMessage}`);
        console.error("Error fetching data:", errorMessage);
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`);
      console.error("Error fetching data:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactForm((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      console.log("Enter key pressed");
    }
  };

  useEffect(() => {
    const title = sitedata?.site_title || "";
    document.title = capitalize(title) + " :: Contact - ClinTa";
    window.scrollTo(0, 0);
    getContactData();
  }, []);

  const getContactData = async () => {
    const response = await SendPostRequest("content/contactus");
    setContactData(response?.data);
  };
  return (
    <div className="page-wrapper-inner">
      <div class="page-wrapper-inner">
        <HeaderWrapper />
        <div className="page-title-wrap typo-white">
          <div
            className="page-title-wrap-inner section-bg-img"
            style={{
              backgroundImage: "url(/assets/images/bg/page-title-bg.jpg)",
            }}
          >
            <span className="black-overlay"></span>
            <div className="container">
              <div className="row text-left">
                <div className="col-md-12">
                  <div className="page-title-inner">
                    <h1 className="page-title mb-0">Contact Us</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section
          id="contact-section"
          className="contact-section pad-bottom-none"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-4 mb-lg-0 mb-4">
                <div
                  className="div-bg-img b-radius-20"
                  style={{
                    backgroundImage: "url(/assets/images/bg/bg-2.jpg)",
                    height: "290px",
                  }}
                >
                  <div className="f-box c-page text-center typo-white">
                    <div className="feature-icon margin-bottom-10">
                      <i className="ti-location-pin"></i>
                    </div>
                    <div className="feature-content">
                      <div className="feature-title">
                        <h5 className="mb-2">Our Location</h5>
                      </div>
                      <p className="mb-0">{sitedata?.site_address || "--"}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 mb-lg-0 mb-4">
                <div
                  className="div-bg-img b-radius-20"
                  style={{
                    backgroundImage: "url(/assets/images/bg/bg-2.jpg)",
                    height: "290px",
                  }}
                >
                  <div className="f-box c-page text-center typo-white">
                    <div className="feature-icon margin-bottom-10">
                      <i className="ti-headphone-alt"></i>
                    </div>
                    <div className="feature-content">
                      <div className="feature-title">
                        <h5 className="mb-2">Phone Number</h5>
                      </div>
                      <a
                        href={`https://wa.me/${sitedata?.site_contact}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {sitedata?.site_contact || "--"}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="div-bg-img b-radius-20"
                  style={{
                    backgroundImage: "url(/assets/images/bg/bg-2.jpg)",
                    height: "290px",
                  }}
                >
                  <div className="f-box c-page text-center typo-white">
                    <div className="feature-icon margin-bottom-10">
                      <i className="ti-email"></i>
                    </div>
                    <div className="feature-content">
                      <div className="feature-title">
                        <h5 className="mb-2">Email Address</h5>
                      </div>
                      <a href={`mailto:${sitedata?.site_email}`}>
                        {sitedata?.site_email || "--"}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="section-contact" className="section-contact">
          <div className="container">
            <div className="row">
              <p className="form-message" style={{ display: "none" }}></p>
              <div className="contact-form" data-animation="fadeInLeft">
                <form
                  ref={formRef}
                  onSubmit={handleSubmit}
                  onKeyDown={handleKeyDown}
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-text form-group">
                        <input
                          type="text"
                          className="input-name form-control"
                          placeholder="Full Name"
                          name="fullname"
                          value={contactForm?.fullname}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="input-email form-group">
                        <input
                          type="email"
                          className="input-email form-control"
                          placeholder="Email"
                          name="email"
                          value={contactForm?.email}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="input-email form-group">
                        <input
                          type="text"
                          className="input-phone form-control"
                          placeholder="Phone"
                          name="phone"
                          value={contactForm?.phone}
                          onChange={handleInputChange}
                          maxLength={11}
                          minLength={10}
                        />
                      </div>
                      <div className="textarea-message form-group">
                        <textarea
                          className="textarea-message form-control"
                          placeholder="Message"
                          rows="4"
                          name="message"
                          value={contactForm?.message}
                          onChange={handleInputChange}
                        ></textarea>
                      </div>
                      <button
                        className="btn btn-default btn-block"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Sending..." : "Send Now"}{" "}
                        <i className="icon-paper-plane ms-1"></i>
                      </button>
                    </div>
                    <div className="col-md-6 pad-top-md-30">
                      <div className="cs_map contact-map">
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              contactData &&
                              contactData.length > 0 &&
                              contactData[0].google_map
                                ? contactData[0].google_map
                                : "",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>

        <FooterWrapper />
      </div>
    </div>
  );
}

export default FirstContactus;
