import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { SendPostRequest } from "../http";
import { FloatingWhatsApp } from "react-floating-whatsapp";

export default function Footer({
  showSection = true,
  showAppointmentbutton = true,
}) {
  const sitedata = useSelector((store) => store.global.loginData);
  const location = useLocation();
  const isDashboardRoute = location.pathname.startsWith("/patient");
  const [contactData, setContactData] = useState([]);
  const [isVisible, setIsVisible] = useState(false);


  const getContactData = async () => {
    const response = await SendPostRequest("content/contactus");
    setContactData(response?.data);
  };
  const [hostpitalAbout, setHostpitalAbout] = useState({});
  const getHostpitalAboutData = async () => {
    const response = await SendPostRequest("content/about");
    setHostpitalAbout(response?.data);
  };

  useEffect(() => {
    getContactData();
    getHostpitalAboutData();
  }, []);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    setIsVisible(scrollTop > 500);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      duration: 2000,
    });
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // const themeColor = sitedata?.theme_color.replace(".css", "");

  let themeColor;
  if (sitedata) {
    themeColor = sitedata?.theme_color?.split(".css").join("");
  } else {
  }

  const [backgroundImage, setBackgroundImage] = useState("");

  if (sitedata) {
    themeColor = sitedata?.theme_color?.split(".css").join("");
  } else {
  }
  useEffect(() => {
    let themeColor;
    if (sitedata) {
      themeColor = sitedata?.theme_color?.split(".css").join("");
    } else {
    }

    setBackgroundImage(`/assets/img/doctors/banner_bg_${themeColor}.svg`);
  }, [sitedata?.theme_color]);


  return (
    <>
      <footer className="cs_footer cs_style_1 cs_heading_color">
        <div className="cs_footer_main"></div>

        <div className="cs_footer_bottom footer-bg-light">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="footer-title-wrapper">
                  <img
                    src={
                      sitedata?.site_logo
                        ? `${sitedata.site_logo}`
                        : "/assets/img/hospital-static.jpg"
                    }
                    alt="Logo"
                    style={{
                      height: "75px",
                      borderRadius: "10px",
                      width: "75px",
                    }}
                  />
                  <h4>{sitedata?.site_title}</h4>
                </div>
                <div className="footer-intro-content mt-3">
                  {hostpitalAbout?.intro ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: hostpitalAbout?.intro,
                      }}
                    />
                  ) : (
                    "Coming soon! Awaited content to be revealed shortly."
                  )}
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-4 mt-4">
                <h2 className="footer-main-headings">Links</h2>
                <ul className="footer-links-wrapper">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/">Introduction</Link>
                  </li>
                  <li>
                    <Link to="/gallery">Gallery</Link>
                  </li>
                  <li>
                    <Link to="/facilities">Facilities</Link>
                  </li>
                  <li>
                    <Link to="/services">Services</Link>
                  </li>
                  <li>
                    <Link to="/news">News</Link>
                  </li>
                  <li>
                    <Link to="/blogs">Blogs</Link>
                  </li>
                </ul>
              </div>
              <div className="col-lg-3 col-md-8 col-8 mt-4">
                <h2 className="cs_white_color footer-main-headings">
                  Contact Us
                </h2>
                <div>
                  <div className="footer_social_links_wrapper">
                    <div className="footer_social_links">
                      <Link>
                        <i className="fa fa-phone"></i>
                      </Link>
                    </div>
                    <p className="">
                      {contactData &&
                      contactData[0]?.land_line_no?.length > 0 ? (
                        <a href={`tel:${contactData[0].land_line_no}`}>
                          {contactData[0].land_line_no}
                        </a>
                      ) : (
                        "--"
                      )}
                    </p>
                  </div>

                  <div className="footer_social_links_wrapper">
                    <div className="footer_social_links">
                      <Link>
                        <i className="fa fa-envelope"></i>
                      </Link>
                    </div>
                    <div>
                      <p className="">
                        {contactData && contactData.length > 0 ? (
                          <a
                            href={`mailto:${
                              contactData?.email?.length > 0
                                ? contactData[0].email
                                : "info@reliancehospital.org"
                            }`}
                          >
                            {contactData[0].email}
                          </a>
                        ) : (
                          "--"
                        )}
                      </p>
                    </div>
                  </div>
                </div>

                <div className=" py-3">
                  {sitedata?.site_extra?.facebook_link === "" &&
                  sitedata?.site_extra?.linkedin_link === "" &&
                  sitedata?.site_extra?.twitter_link === "" ? (
                    ""
                  ) : (
                    <div>
                      <div className="footer-follow-links">
                        <h5>Follow Us</h5>
                        <div className="cs_social_links">
                          {sitedata?.site_extra?.facebook_link && (
                            <Link
                              target="blank"
                              to={sitedata?.site_extra?.facebook_link}
                            >
                              <i className="fa-brands fa-facebook-f"></i>
                            </Link>
                          )}
                          {sitedata?.site_extra?.linkedin_link && (
                            <Link
                              target="blank"
                              to={sitedata?.site_extra?.linkedin_link}
                            >
                              <i className="fa-brands fa-linkedin-in"></i>
                            </Link>
                          )}
                          {sitedata?.site_extra?.twitter_link && (
                            <Link
                              target="blank"
                              to={sitedata?.site_extra?.twitter_link}
                            >
                              <i className="fa-brands fa-twitter"></i>
                            </Link>
                          )}
                          {sitedata?.site_extra?.instagram_link && (
                            <Link
                              target="blank"
                              to={sitedata?.site_extra?.instagram_link}
                            >
                              <i className="fa-brands fa-instagram"></i>
                            </Link>
                          )}
                          {sitedata?.site_extra?.youtube_link && (
                            <Link
                              target="blank"
                              to={sitedata?.site_extra?.youtube_link}
                            >
                              <i className="fa-brands fa-youtube"></i>
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  {/* <div className="cs_copyright company-copyrights">
                Copyright © 2024 ClinTa - Powered by D-Right Technologies.
              </div> */}
                  {/* <div className="cs_copyright company-copyrights">
                Copyright © 2024 ClinTa - Powered by Holistic Solutions (PVT)
                LTD.
              </div> */}
                </div>
              </div>
            </div>

            <hr style={{ opacity: "100%", marginBottom: "28px" }} />
            <div className="cs_copyright company-copyrights text-center mb-2">
              Copyright © {new Date()?.getFullYear()} ClinTa - Powered by  <a
                          href="https://dright.net/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {" "}
                          D-Right Technologies
                        </a>.
            </div>
          </div>
        </div>
      </footer>

      <span
        className={`cs_scrollup upside-scroll-arrow ${
          isVisible ? "visible" : ""
        }`}
        onClick={scrollToTop}
      >
        <i className="fa-solid fa-arrow-up"></i>
      </span>

      <button className="floadting-appointment-btn">
        <Link to={"/appointment"}>Appointment</Link>
      </button>
      {/* {sitedata?.whatsapp_chat && (
        <FloatingWhatsApp
          phoneNumber={sitedata?.whatsapp_chat}
          message="Hello!"
          accountName={sitedata?.site_title}
           statusMessage="For Appointments Only"
          avatar={sitedata?.site_logo}
        />
      )} */}

      {!isDashboardRoute && sitedata?.whatsapp_chat && (
        <FloatingWhatsApp
          phoneNumber={sitedata?.whatsapp_chat}
          message="Hello!"
          accountName={sitedata?.site_title}
          statusMessage="For Appointments Only"
          avatar={sitedata?.site_logo}
        />
      )}
    </>
  );
}
