import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function DayCounter() {
  const sitedata = useSelector((store) => store.global.loginData);

  // const counterDate = "2025-03-08";
  const counterDate = sitedata?.promotions?.expiry_date;

  const formattedExpiryDate = counterDate ? `${counterDate}T23:59:59` : null;

  const [timeRemaining, setTimeRemaining] = useState({
    days: "00",
    hours: "00",
    minutes: "00",
    seconds: "00",
  });
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    if (!formattedExpiryDate) {
      setIsExpired(true);
      return;
    }

    const targetDate = new Date(formattedExpiryDate).getTime();

    const updateTimer = () => {
      const now = new Date().getTime();
      const timeDiff = targetDate - now;

      if (timeDiff <= 0) {
        setIsExpired(true);
        clearInterval(timerInterval);
        return;
      }

      const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);

      setTimeRemaining({
        days: String(days).padStart(2, "0"),
        hours: String(hours).padStart(2, "0"),
        minutes: String(minutes).padStart(2, "0"),
        seconds: String(seconds).padStart(2, "0"),
      });
    };

    const timerInterval = setInterval(updateTimer, 1000);
    updateTimer(); // Run immediately

    return () => clearInterval(timerInterval);
  }, [formattedExpiryDate]);

  // Conditionally render the section or the empty div based on the expiration status
  return isExpired ? (
    <div className="mt-5"></div>
  ) : (
    <div className="pt-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 d-flex justify-content-center align-items-center counter-image-wrapper">
            <img
              src={
                sitedata?.promotions?.image ||
                "/assets/images/counter_image.jpeg"
              }
              alt="Counter Image"
              className="img-fluid"
            />
          </div>

          <div className="col-lg-4 d-flex justify-content-center align-items-center">
            <div className="counter-main-wrapper">
              <div className="row">
                <div className="col-6">
                  <div className="day-counter-wrapper">
                    <h3 className="count-view theme-color mb-2">
                      {timeRemaining.days}
                    </h3>
                    <span>Days</span>
                  </div>
                </div>
                <div className="col-6">
                  <div className="day-counter-wrapper">
                    <h3 className="count-view theme-color mb-2">
                      {timeRemaining.hours}
                    </h3>
                    <span>Hours</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="day-counter-wrapper">
                    <h3 className="count-view theme-color mb-2">
                      {timeRemaining.minutes}
                    </h3>
                    <span>Minutes</span>
                  </div>
                </div>
                <div className="col-6">
                  <div className="day-counter-wrapper">
                    <h3 className="count-view theme-color mb-2">
                      {timeRemaining.seconds}
                    </h3>
                    <span>Seconds</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DayCounter;
