import React, { useState } from "react";
import Topbar from "./Topbar";
import SecondHospitalNavbar from "./Hospital/SecondHospitalNavbar";
import SecondDoctorNavbar from "./Doctor/SecondDoctorNavbar";
import { useSelector } from "react-redux";
import HospitalMobileNavbar from "./HospitalMobileNavbar";
import DoctorMobileNavbar from "./DoctorMobileNavbar";
function SecondHeader() {
  const [toggleNav, setToggleNav] = useState(false);
  const [searchModal, setSearchModal] = useState(false);
  const sitedata = useSelector((store) => store.global.loginData);

  return (
    <div>
      <header>
        {sitedata?.site_type === "hospitals" ? (
          <HospitalMobileNavbar
            toggleNav={toggleNav}
            setToggleNav={setToggleNav}
            setSearchModal={setSearchModal}
          />
        ) : (
          <DoctorMobileNavbar
          toggleNav={toggleNav}
            setToggleNav={setToggleNav}
            setSearchModal={setSearchModal}
          />
        )}

        <div
          className={`overlay-search text-center ${searchModal ? "" : "hide"}`}
        >
          <span
            onClick={() => setSearchModal(false)}
            className="close close-light overlay-search-close"
          ></span>
          <div className="search-form-wrapper">
            <form
              className="navbar-form search-form sliding-search-form"
              role="search"
            >
              <div className="input-group add-on">
                <input
                  className="form-control"
                  placeholder="Search for.."
                  name="srch-term"
                  type="text"
                />
                <div className="input-group-btn">
                  <button className="btn btn-default search-btn" type="submit">
                    <i className="ti-arrow-right"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="header-inner header-1 d-none d-md-block">
          <Topbar />
          {sitedata?.site_type === "hospitals" ? (
            <SecondHospitalNavbar />
          ) : (
            <SecondDoctorNavbar />
          )}
        </div>
      </header>
    </div>
  );
}

export default SecondHeader;
