import React, { useEffect, useState } from "react";
import Select from "react-select";
import Header from "./Header";
import Footer from "./Footer";
import { useSelector } from "react-redux";
import Captcha from "./Captcha/Captcha";
import { SendPostRequest } from "../http";
import { toast } from "react-toastify";
import { capitalize } from "@mui/material";

export default function Appointments() {
  const sitedata = useSelector((store) => store.global.loginData);
  const patientLogin = useSelector((state) => state.patient.patientLogin);

  const [inputValues, setInputValues] = useState({});
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [doctorSelected, setDoctorSelected] = useState(null);
  const [formateDoctor, setFormateDoctor] = useState([]);
  const [allDoctors, setAllDoctors] = useState([]);

  const [relation, setRelation] = useState(null);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [selectedDoctorId, setSelectedDoctorId] = useState(null);
  const [doctorOption, setDoctorOption] = useState([]);
  const [gender, setGender] = useState(null);
  const [shift, setShift] = useState(null);

  const [hospitalSlots, setHospitalSlots] = useState([]);
  const [displayedValue, setDisplayedValue] = useState(null);

  useEffect(() => {
    if (patientLogin) {
      setInputValues({
        patient_name: patientLogin?.fullname,
        email: patientLogin?.email,
        contact_number: patientLogin?.contact_num,
      });
    }
    const title = sitedata?.site_title || "";
    document.title = capitalize(title) + " :: Online Appointments - ClinTa";
    window.scrollTo(0, 0);
    handleRefresh();
  }, []);

  // fetching doctors lists
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await SendPostRequest("doctors/list");
        setAllDoctors(response.data);
        const formattedDoctors = response.data
          .filter((doctor) => doctor.speciality)
          .map((doctor) => ({
            value: doctor.speciality,
            label: doctor.speciality,

            // ...(doctor.telemedicine_id === 1 && { value: "E-Health", label: "E-Health" }),
          }));

        const doctorsWithOptions = [
          { value: "All", label: "All" },
          { value: "E-Health", label: "E-Health" },
          ...formattedDoctors,
        ];

        setFormateDoctor(doctorsWithOptions);

        // const uniqueDepartments = Array.from(
        //   new Set(
        //     response.data.map((doctor) =>{
        //       doctor.speciality ? doctor.speciality : "ALL"
        //     }
        //     )
        //   )
        // );
        // setSpeciality(uniqueDepartments);
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };

    fetchData();
  }, []);

  // get hospital slots
  useEffect(() => {
    const getHospitalSlots = async () => {
      const date = inputValues.appointment_date;
      const type = sitedata.site_type;
      let allInputs;

      try {
        allInputs = { type, doct_uid: selectedDoctorId, date };
        if (date) {
          const res = await SendPostRequest("hospitals/slots", allInputs);
          setHospitalSlots(res.data);
        }
      } catch (error) {
        console.error("Error fetching doctor's slots:", error);
      }
    };
    getHospitalSlots();
  }, [inputValues.appointment_date]);

  const handleDoctorsChange = (selectedOption) => {
    setDoctorSelected(selectedOption.value);

    let filteredDoctors;

    if (selectedOption.value === "All") {
      // If "All" is selected, store all doctors
      filteredDoctors = allDoctors;
    } else if (selectedOption.value === "E-Health") {
      // If "E-Health" is selected, filter doctors with telemedicine_id === 1
      filteredDoctors = allDoctors.filter(
        (doctorData) => doctorData.telemedicine_id === 1
      );
    } else {
      // Otherwise, filter doctors based on selected speciality
      filteredDoctors = allDoctors.filter(
        (doctorData) => doctorData.speciality === selectedOption.value
      );
    }
    const formattedDoctors = filteredDoctors.map((doctor) => ({
      value: doctor.full_name,
      label: doctor.full_name,
      id: doctor.uid,
    }));
    setDoctorOption(formattedDoctors);
  };

  // useEffect(() => {
  //   setSelectedDoctor();
  // }, [doctorSelected]);

  const reasonOptions = [
    { value: "son-of", label: "Son Of" },
    { value: "daughter-of", label: "Daughter Of" },
    { value: "father-of", label: "Father Of" },
    { value: "mother-of", label: "Mother Of" },
    { value: "husband-of", label: "Husband Of" },
    { value: "wife-of", label: "Wife Of" },
  ];
  const genderOptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "other", label: "Other" },
  ];

  const [shiftOptions, setShiftOptions] = useState([]);

  useEffect(() => {
    const newShiftOptions = [];

    // Check if hospitalSlots is empty
    if (
      Object.keys(hospitalSlots).length === 0 &&
      inputValues.appointment_date
    ) {
      // If empty, set default values
      newShiftOptions.push({ value: "evening", label: "Evening" });
      newShiftOptions.push({ value: "morning", label: "Morning" });
    } else {
      // If not empty, populate shift options from hospitalSlots
      for (const key in hospitalSlots) {
        if (hospitalSlots.hasOwnProperty(key)) {
          newShiftOptions.push({ value: key, label: hospitalSlots[key] });
        }
      }
    }

    setShiftOptions(newShiftOptions);
    setDisplayedValue(null);
  }, [hospitalSlots]);

  const handleReasonChange = (selectedOption) => {
    setRelation(selectedOption.value);
  };
  const handleDoctorChange = (selectedOption) => {
    setSelectedDoctor(selectedOption.value);
    setSelectedDoctorId(selectedOption.id);
  };
  const handleGenderChange = (selectedOption) => {
    setGender(selectedOption.value);
  };
  const handleShiftChange = (selectedOption) => {
    setShift(selectedOption.value);
    setDisplayedValue(selectedOption?.label);
  };

  const [captcha, setCaptcha] = useState("");
  const [input_captcha, setInputCaptcha] = useState("");
  const [check_captcha, setCheckCaptcha] = useState("");
  const [appointmentisLoading, setAppointmentisLoading] = useState(false);

  const textColorClass =
    check_captcha === "Captcha Verified" ? "text-success" : "text-danger";

  const handleInputChange = (e) => {
    setInputCaptcha(e.target.value);
  };

  const handleRefresh = () => {
    const newCaptcha = generateRandomCaptcha();
    setCaptcha(newCaptcha);
  };

  const generateRandomCaptcha = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const captchaLength = 6;
    let newCaptcha = "";

    for (let i = 0; i < captchaLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      newCaptcha += characters.charAt(randomIndex);
    }

    return newCaptcha;
  };

  const formatDate = (inputDate) => {
    const dateObject = new Date(inputDate);

    const year = dateObject.getFullYear();
    const month = dateObject.getMonth() + 1;
    const day = dateObject.getDate();
    const formattedDate = `${year}-${month < 10 ? "0" + month : month}-${
      day < 10 ? "0" + day : day
    }`;

    return formattedDate;
  };

  const handleContactNumber = (e) => {
    let val = e.target.value;
    if (val.length <= 14) {
      setInputValues((prevValues) => ({
        ...prevValues,
        contact_number: val,
      }));
    }
    return;
  };

  const handleAllInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "appointment_date") {
      const formattedDate = formatDate(value);
      setInputValues((prevValues) => ({
        ...prevValues,
        appointment_date: formattedDate,
      }));
    } else {
      setInputValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  const formSubmit = async (e) => {
    e.preventDefault();
    setAppointmentisLoading(true);

    if (!inputValues.patient_name) {
      toast.error("Enter patient name");
      setAppointmentisLoading(false);
      return;
    } else if (!gender) {
      toast.error("Select Gender");
      setAppointmentisLoading(false);
      return;
    }
    //  else if (
    //   inputValues.email &&
    //   (!inputValues.email.includes(".") || !inputValues.email.includes("@"))
    // ) {
    //   toast.error("Invalid email");
    //   setAppointmentisLoading(false);
    //   return;
    // }
    else if (!inputValues.contact_number) {
      toast.error("Enter Number");
      setAppointmentisLoading(false);
      return;
    } else if (
      inputValues.contact_number.length > 14 ||
      inputValues.contact_number.length < 10
    ) {
      toast.error("Invalid Number");
      setAppointmentisLoading(false);
      return;
    } else if (!doctorSelected) {
      toast.error("Select Speciality");
      setAppointmentisLoading(false);
      return;
    } else if (!selectedDoctor) {
      toast.error("Select Doctor");
      setAppointmentisLoading(false);
      return;
    } else if (!inputValues.appointment_date) {
      toast.error("Enter Date");
      setAppointmentisLoading(false);
      return;
    } else if (!shift) {
      toast.error("Select Your Shift");
      setAppointmentisLoading(false);
      return;
    } else if (captcha === input_captcha) {
      setCheckCaptcha("Captcha Verified");
    } else {
      setCheckCaptcha("Captcha failed");
      setAppointmentisLoading(false);
    }

    if (captcha === input_captcha) {
      try {
        const myallInputs = {
          ...inputValues,
          relation,
          doctor_id: selectedDoctorId,
          gender,
          shift,
        };

        // setSelectedDoctor([]);

        const response = await SendPostRequest(
          "doctors/appointment_form",
          myallInputs
        );
        if (response.status === "success") {
          setInputValues({
            patient_name: patientLogin ? patientLogin.fullname : "",
            email: patientLogin ? patientLogin.email : "",
            contact_number: patientLogin ? patientLogin.contact_num : "",
            age: "",
            relation_name: "",
            appointment_date: "",
            remarks: "",
          });
          setDisplayedValue(null);
          setInputCaptcha("");
          setCheckCaptcha("");
          setSelectedDoctorId([]);
          setSelectedDoctor({ value: "" });
          setRelation({ value: "" });
          setShift({ value: "" });
          setGender({ value: "" });
          setDoctorOption([]);
          setDoctorSelected({ value: "" });
          handleRefresh();
        }

        toast.success(response.message);

        setAppointmentisLoading(false);
      } catch (e) {
        toast.error("Something Went Wrong");
        setAppointmentisLoading(false);
      }
    } else {
      setCheckCaptcha("Captcha failed");
      toast.error("Captcha verification failed");
      setAppointmentisLoading(false);
    }
  };

  const [backgroundImage, setBackgroundImage] = useState("");
  let themeColor;
  if (sitedata) {
    themeColor = sitedata?.theme_color?.split(".css").join("");
  } else {
  }
  useEffect(() => {
    // const themeColor = sitedata?.theme_color.replace(".css", "");
    let themeColor;
    if (sitedata) {
      themeColor = sitedata?.theme_color?.split(".css").join("");
    } else {
    }

    setBackgroundImage(`/assets/img/doctors/banner_bg_${themeColor}.svg`);
  }, [sitedata?.theme_color]);

  const todayDate = new Date().toISOString().split("T")[0];


  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      formSubmit(e);
    }
  };
  return (
    <>
      <Header />
      <section
        className="cs_banner cs_style_3 cs_bg_filed"
        // style={{
        //   backgroundImage:
        //     sitedata.site_type === "hospitals"
        //       ? "url('/assets/img/doctors/banner_bg.svg')"
        //       : "url('/assets/img/doctors/banner_bg_gold.svg')",
        // }}

        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="container">
          <div className="cs_banner_text m-0">
            <h2 className="cs_banner_title cs_fs_72">Appointment</h2>
            <p className="cs_banner_subtitle cs_fs_20 mb-0 cs_heading_color">
              Fill out the appointment form below to schedule a consultation
              with one of our healthcare professionals.
            </p>
          </div>
        </div>
        {/* <div className="cs_banner_img">
          <img src="/assets/img/appointments/banner_img.png" alt="Banner" />
        </div> */}
      </section>

      <section>
        <div className="cs_height_200 cs_height_xl_150 cs_height_lg_110"></div>
        <div className="container"   onKeyDown={handleKeyDown}>
          <div className="row">
            <div className="col-lg-7">
              <h2 className="cs_fs_40 cs_medium mb-0">Appointment</h2>
              <div className="cs_height_42 cs_height_xl_25"></div>
              <div className="row">
                <div className="col-lg-6">
                  <label className="cs_input_label cs_heading_color">
                    Patient Name <span className="asterisk">*</span>
                  </label>
                  <input
                    name="patient_name"
                    value={inputValues.patient_name}
                    type="text"
                    className="cs_form_field"
                    placeholder="Enter Name"
                    onChange={handleAllInputChange}
                  />
                  <div className="cs_height_42 cs_height_xl_25"></div>
                </div>
                <div className="col-lg-6">
                  <label className="cs_input_label cs_heading_color">
                    Please Select Relation
                  </label>
                  <Select
                    className="gallery-select-field"
                    name="relation_select"
                    value={relation?.value}
                    options={reasonOptions}
                    onChange={handleReasonChange}
                    placeholder="Select...."
                  />

                  <div className="cs_height_42 cs_height_xl_25"></div>
                </div>
                <div className="col-lg-6">
                  <label className="cs_input_label cs_heading_color">
                    Relation Name
                  </label>
                  <input
                    name="relation_name"
                    value={inputValues?.relation_name}
                    type="text"
                    className="cs_form_field"
                    placeholder="Enter your relation"
                    onChange={handleAllInputChange}
                  />
                  <div className="cs_height_42 cs_height_xl_25"></div>
                </div>

                <div className="col-lg-6">
                  <label className="cs_input_label cs_heading_color">
                    Please Select Gender <span className="asterisk">*</span>
                  </label>
                  <Select
                    value={gender?.value}
                    options={genderOptions}
                    onChange={handleGenderChange}
                    placeholder=" Please Select Gender"
                  />

                  <div className="cs_height_42 cs_height_xl_25"></div>
                </div>

                <div className="col-lg-6">
                  <label className="cs_input_label cs_heading_color">
                    Email
                  </label>
                  <input
                    name="email"
                    value={inputValues?.email}
                    type="email"
                    className="cs_form_field"
                    placeholder="Enter your email"
                    onChange={handleAllInputChange}
                  />
                  <div className="cs_height_42 cs_height_xl_25"></div>
                </div>
                <div className="col-lg-6">
                  <label className="cs_input_label cs_heading_color">
                    Contact Number <span className="asterisk">*</span>
                  </label>
                  <input
                    name="contact_number"
                    value={inputValues?.contact_number}
                    type="text"
                    className="cs_form_field"
                    placeholder="Enter Contact Number"
                    onChange={handleContactNumber}
                    maxLength={14}
                    minLength={10}
                  />
                  <div className="cs_height_42 cs_height_xl_25"></div>
                </div>
                <div className="col-lg-6">
                  <label className="cs_input_label cs_heading_color">
                    Please Select Speciality <span className="asterisk">*</span>
                  </label>
                  <Select
                    value={doctorSelected?.value}
                    onChange={handleDoctorsChange}
                    options={formateDoctor}
                    placeholder="Select speciality"
                  />

                  <div className="cs_height_42 cs_height_xl_25"></div>
                </div>
                <div className="col-lg-6">
                  <label className="cs_input_label cs_heading_color">
                    Please Select Doctor <span className="asterisk">*</span>
                  </label>
                  <Select
                    value={selectedDoctor?.value}
                    options={doctorOption}
                    onChange={handleDoctorChange}
                    placeholder="Select a doctor"
                  />
                  <div className="cs_height_42 cs_height_xl_25"></div>
                </div>

                <div className="col-lg-6">
                  <label className="cs_input_label cs_heading_color">
                    Preferred Date <span className="asterisk">*</span>{" "}
                    {!selectedDoctorId && (
                      <span>Please Select Doctor First</span>
                    )}
                  </label>
                  <div className="cs_with_icon_input">
                    <input
                      disabled={!selectedDoctorId}
                      name="appointment_date"
                      value={inputValues.appointment_date}
                      type="date"
                      className="cs_form_field"
                      id="datepicker"
                      placeholder="August 24, 2023"
                      onChange={handleAllInputChange}
                      min={todayDate}
                    />

                    {/* <i className="fa-solid fa-calendar-days"></i> */}
                  </div>
                  <div className="cs_height_42 cs_height_xl_25"></div>
                </div>

                <div className="col-lg-6">
                  <label className="cs_input_label cs_heading_color">
                    Preferred Time <span className="asterisk">*</span>
                  </label>
                  <Select
                    className="gallery-select-field"
                    value={
                      displayedValue !== null
                        ? { value: hospitalSlots?.value, label: displayedValue }
                        : null
                    }
                    options={shiftOptions}
                    onChange={handleShiftChange}
                    placeholder={
                      inputValues?.appointment_date
                        ? "Select Shift"
                        : "Please Select Date First"
                    }
                  />

                  <div className="cs_height_42 cs_height_xl_25"></div>
                </div>

                <div className="col-lg-6">
                  <label className="cs_input_label cs_heading_color">Age</label>
                  <input
                    name="age"
                    value={inputValues.age}
                    type="number"
                    className="cs_form_field"
                    placeholder="Enter your age"
                    onChange={handleAllInputChange}
                  />
                  <div className="cs_height_42 cs_height_xl_25"></div>
                </div>

                <div className="col-lg-6">
                  <Captcha
                    captcha={captcha}
                    inputCaptcha={input_captcha}
                    textColorClass={textColorClass}
                    checkCaptcha={check_captcha}
                    handleInputChange={handleInputChange}
                  />
                </div>

                <div className="col-lg-12 appointment-textarea">
                  <label className="cs_input_label cs_heading_color">
                    Remarks
                  </label>
                  <textarea
                    rows={5}
                    name="remarks"
                    value={inputValues.remarks}
                    type="number"
                    className="cs_form_field"
                    placeholder="Write Your remarks here"
                    onChange={handleAllInputChange}
                  ></textarea>
                  <div className="cs_height_42 cs_height_xl_25"></div>
                </div>

                <div className="col-lg-12">
                  <div className="cs_height_18"></div>
                  <button
                    className="cs_btn cs_style_1"
                    onClick={formSubmit}
                    disabled={appointmentisLoading}
                  >
                    <span>
                      {" "}
                      {appointmentisLoading ? "Please Wait" : "Submit "}
                    </span>
                    <i>
                      <img src="/assets/img/icons/arrow_white.svg" alt="Icon" />
                      <img src="/assets/img/icons/arrow_white.svg" alt="Icon" />
                    </i>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-4 offset-lg-1">
              <div className="cs_height_lg_100"></div>
              <h2 className="cs_fs_40 cs_medium mb-0">Contact Info</h2>
              <div className="cs_height_60 cs_height_xl_40"></div>
              <img
                src="/assets/img/appointments/appointment_img.jpg"
                alt="Appointment"
                className="cs_radius_25 w-100"
              />
              <div className="cs_height_100 cs_height_xl_60"></div>
              <ul className="cs_contact_info cs_style_1 cs_mp0">
                <li>
                  <h3 className="cs_fs_24 cs_semibold mb-0">Phone</h3>
                  <p className="cs_iconbox_subtitle mb-0 cs_heading_color">
                    <a href="tel:+92515732855">051 5732 855</a>
                  </p>
                </li>
                <li>
                  <h3 className="cs_fs_24 cs_semibold mb-0">Email Us</h3>
                  <p className="cs_iconbox_subtitle mb-0 cs_heading_color">
                    <a href="mailto:info@reliancehospital.org">
                      info@reliancehospital.org
                    </a>
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <div className="cs_height_42 cs_height_xl_25"></div>
      <Footer showAppointmentbutton={false} />
    </>
  );
}
