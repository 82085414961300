import { capitalize } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SendPostRequest } from "../../http";
import { useSelector } from "react-redux";
import HeaderWrapper from "../Header/HeaderWrapper";
import FooterWrapper from "../Footer/FooterWrapper";
import { Link } from "react-router-dom";
import Spinner from "../Spinner";
import { formatDate } from "../../Helper/Helper";

function CareerListing() {
  const sitedata = useSelector((store) => store.global.loginData);
  const [careersData, setCareersData] = useState([]);
  console.log("careeeerrrors", careersData);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const title = sitedata?.site_title || "";
    document.title = capitalize(title) + " :: Contact - ClinTa";
    window.scrollTo(0, 0);
    getCareerData();

    // const date = "2024-07-16 00:00:00";
  }, []);

  function isFutureDate(dateString) {
    const inputDate = new Date(dateString);
    const currentDate = new Date();

    currentDate.setHours(0, 0, 0, 0);

    return inputDate > currentDate;
  }

  const getCareerData = async () => {
    const response = await SendPostRequest("careers/jobs");
    setCareersData(response?.data);
    setLoading(false);
  };

  return (
    <div className="page-wrapper-inner">
      <div className="page-wrapper-inner">
        <HeaderWrapper />
        <div className="page-title-wrap typo-white">
          <div
            className="page-title-wrap-inner section-bg-img"
            style={{
              backgroundImage: "url(/assets/images/bg/page-title-bg.jpg)",
            }}
          >
            <span className="black-overlay"></span>
            <div className="container">
              <div className="row text-left">
                <div className="col-md-12">
                  <div className="page-title-inner">
                    <h1 className="page-title mb-0">Careers</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section id="career-section" className="career-section py-5">
          <div class="offset-md-2 col-md-8 ">
            <div class="title-wrap text-center ">
              <div class="section-title margin-bottom-80">
                <h2 class="section-title mb-0 text-uppercase">
                  Available <span class="theme-color">Positions</span>
                </h2>
                <span class="section-border-bottom center"></span>
                <p>
                  Offer a wide range of services to help businesses establish.
                </p>
              </div>
            </div>
          </div>
          <div className="container">
            {loading ? (
              <div className="text-center">
                <Spinner />
              </div>
            ) : careersData?.jobs?.length > 0 ? (
              <div className="row">
                {careersData.jobs.map((data, index) => (
                  <div className="col-lg-4 col-md-6 col-12 mb-4" key={index}>
                    <div className="career-box-details pad-30 box-shadow bg-white b-radius-10">
                      <div className="job-type text-uppercase mb-0 theme-color">
                        {data?.job_type}
                      </div>
                      <div className="job-title margin-bottom-10">
                        <h4>{data?.title}</h4>
                      </div>
                      <div className="job-description margin-bottom-20">
                        <p className="mb-0">
                          <strong>Experience: </strong>
                          {data?.experience}
                        </p>
                        <p className="mb-0">
                          <strong>Deadline: </strong>
                          {formatDate(data?.dead_line)}
                        </p>
                      </div>

                      {isFutureDate(data?.published_at) ? (
                        <div className="apply-btn career-apply-btn">
                          <Link className="btn btn-default" title="Apply Now">
                            Coming Soon
                          </Link>
                        </div>
                      ) : (
                        <div className="apply-btn career-apply-btn">
                          <Link
                            to={"/drop-cv"}
                            className="btn btn-default"
                            title="Apply Now"
                          >
                            Apply Now
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-center">
                <p>
                  No available positions at the moment. Please check back later.
                </p>
              </div>
            )}
          </div>
        </section>

        <FooterWrapper />
      </div>
    </div>
  );
}

export default CareerListing;
