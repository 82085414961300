import React, { useEffect, useState } from "react";
import { SendPostRequest } from "../../http";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import Spinner from "../Spinner";
import OwlCarousel from "react-owl-carousel";
import ParagraphLimiter from "../Theme2DashboardComponents/ParagraphLimiter";
import { limitParagraphByCharLimit } from "../../Helper/Helper";

function FirstFacilities() {
  const [facilitiesData, setFacilitiesData] = useState([]);
  const random = Math?.round(Math?.random() * facilitiesData?.length);

  useEffect(() => {
    getFacilitiesData();
  }, []);

  const getFacilitiesData = async () => {
    const response = await SendPostRequest("facilities");
    setFacilitiesData(response?.data);
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  const [show, setShow] = useState(false);
  const [facilitiesIndex, setFacilitiesIndex] = useState(null);

  const options = {
    loop: true,
    nav: false,
    dots: false,
    autoplay: true,
    autoplayTimeout: 2500,
    autoplayHoverPause: true,
    smartSpeed: 1000,
    margin: 30,
    responsive: {
      0: { items: 1 },
      600: { items: 2 },
      1000: { items: 3 },
    },
  };

  

  return (
    <>
      {facilitiesData?.length > 0 ? (
        <section id="feature-section" className="feature-section pb-4 pt-5">
          <div className="container">
            <div className="row mb-4">
              <div className="offset-md-2 col-md-8">
                <div className="title-wrap text-center">
                  <div className="section-title margin-bottom-40">
                    <h2 className="title mb-0 text-uppercase">
                      Our <span className="theme-color">Facilities</span>
                    </h2>
                    <span className="section-border-bottom center"></span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row" data-animation="fadeInDown">
              {facilitiesData?.length === 0 ? (
                <div
                  style={{ marginBottom: "100px" }}
                  className="d-flex align-items-center justify-content-center"
                >
                  <Spinner />
                </div>
              ) : (
                <OwlCarousel className="service-main-wrapper" {...options}>
                  {facilitiesData?.map((data, index) => (
                    <div key={index} className="item">
                      <div className="feature-box-wrap f-box-style-1 relative">
                        <div className="feature-box-details text-center">
                          <div
                            className="feature-img"
                            style={{
                              backgroundImage: `url(${
                                data?.facility_image ||
                                "/assets/images/default.jpg"
                              })`,
                              backgroundSize: "cover",
                              backgroundPosition: "top center",
                              width: "100%",
                              height: "300px",
                            }}
                          ></div>
                          <div className="feature-content mt-4 mb-3">
                            <div className="feature-title relative margin-bottom-5">
                              <h5>{data?.facility_title}</h5>
                            </div>
                            <p
                              dangerouslySetInnerHTML={{
                                __html:
                                  limitParagraphByCharLimit(
                                    data?.facility_description,
                                    140
                                  ) + "...",
                              }}
                            />

                            {/* <p>
                              {limitParagraphByCharLimit(paragraph, charLimit)}
                            </p> */}

                            {/* <ParagraphLimiter text={data?.facility_description} charLimit={5} /> */}

                            {/* <p
                              dangerouslySetInnerHTML={{
                                __html: data?.facility_description
                                  ? truncateText(data.facility_description, 200)
                                  : "",
                              }}
                            /> */}
                            <div className="button">
                              <Link
                                className="btn-link"
                                to={`/facility/${data?.facility_slug
                                  ?.replaceAll(" ", "-")
                                  ?.replaceAll("/", "-")}`}
                              >
                                Read More
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </OwlCarousel>
              )}

              {/* {facilitiesData?.length === 0 ? (
                <div
                  style={{ marginBottom: "100px" }}
                  className="d-flex align-items-center justify-content-center"
                >
                  <Spinner />
                </div>
              ) : (
                facilitiesData
                  ?.slice(
                    random >= 3 ? random - 3 : random,
                    random < 3 ? random + 3 : random
                  )
                  ?.map((data, index) => (
                    <div key={index} className="col-lg-4 pad-top-md-30">
                      <div className="feature-box-wrap f-box-style-1 relative">
                        <div className="feature-box-details text-center">
                          <div
                            className="feature-img"
                            style={{
                              backgroundImage: `url(${
                                data?.facility_image ||
                                "/assets/images/default.jpg"
                              })`,
                              backgroundSize: "cover",
                              backgroundPosition: "top center",
                              width: "100%",
                              height: "300px",
                            }}
                          ></div>
                          <div className="feature-content mt-4 mb-3">
                            <div className="feature-title relative margin-bottom-5">
                              <h5>{data?.facility_title}</h5>
                            </div>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: data?.facility_description
                                  ? truncateText(data.facility_description, 200)
                                  : "",
                              }}
                            />
                            <div className="button">
                              <Link
                                className="btn-link"
                                to={`/facility/${data?.facility_slug
                                  ?.replaceAll(" ", "-")
                                  ?.replaceAll("/", "-")}`}
                                // onClick={() => handleShow(index)}
                              >
                                Read More
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
              )} */}
            </div>
          </div>
        </section>
      ) : null}

      {/* <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="review-modal-title">
            {facilitiesData[facilitiesIndex]?.facility_title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            dangerouslySetInnerHTML={{
              __html: facilitiesData[facilitiesIndex]?.facility_description,
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
}

export default FirstFacilities;
