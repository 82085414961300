import React from "react";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";

const VideoBgSection = () => {
  const sitedata = useSelector((store) => store.global.loginData);

  const extractVideoUrl = (iframeString) => {
    const match = iframeString?.match(/src="([^"]+)"/);
    return match ? match[1] : null;
  };

  const videoUrl = extractVideoUrl(sitedata?.footer_video_link);

  if (!videoUrl) {
    return null; 
  }

  return (
    <div id="video-bg-section" className="video-bg-section my-video">
      <div className="row">
        <div className="col-lg-12">
          <div id="vbg2">
            <ReactPlayer
              url={videoUrl}
              playing={true}
              controls={true}
              width="100%"
              height="500px"
              config={{
                youtube: {
                  playerVars: {
                    autoplay: 1,
                    start: 10,
                    mute: 1,
                  },
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoBgSection;
