import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { SendPostRequest } from "../http";
import { Link } from "react-router-dom";
import createSlug from "../utils/CreateSlug";
import Spinner from "./Spinner";

function SecondMeetDoctors() {
  const [doctorsData, setDoctorsData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getDoctorsData();
  }, []);

  const getDoctorsData = async () => {
    try {
      const response = await SendPostRequest("doctors/list");
      setDoctorsData(response?.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching doctors data:", error);
    }
  };

  const owlOptions = {
    loop: true,
    nav: false,
    dots: false,
    autoplay: true,
    autoplayTimeout: 2500,
    autoplayHoverPause: true,
    smartSpeed: 1000,
    margin: 30,
    responsive: {
      0: { items: 1 },
      600: { items: 2 },
      1000: { items: 4 },
    },
  };

  return (
    <>
      {loading ? (
        <div
          style={{ marginBottom: "100px" }}
          className="d-flex align-items-center justify-content-center "
        >
          <Spinner />
        </div>
      ) : doctorsData?.length > 0 ? ( 
        <div id="team-section" className="team-section my-2">
          <div className="container">
            <div className="row">
              <div className="offset-md-2 col-md-8">
                <div className="title-wrap text-center relative  margin-bottom-60">
                  <div className="section-title margin-bottom-0">
                    <h2 className="section-title mb-0 text-uppercase">
                      Meet The Team
                    </h2>
                    <span className="section-border-bottom center"></span>
                  </div>
                </div>
              </div>
              <OwlCarousel
                className="owl-carousel team-main-wrapper"
                {...owlOptions}
              >
                {doctorsData?.map((data, index) => (
                  <div className="item" key={index}>
                    <div className="team-style-1">
                      <div className="team-inner margin-bottom-20">
                        <div className="team-thumb mb-0 relative">
                          {/* <div className="team-overlay"></div> */}
                          <Link
                            to={`/doctor/${data?.uid}/${createSlug(
                              data?.full_name
                            )}`}
                          >
                            {/* <img
                              src={data?.profile}
                              className="img-fluid thumb w-100"
                              width="270"
                              style={{ height: "270px" }}
                              alt="team-img"
                            /> */}
                            <div
                              style={{
                                height: "270px",
                                width: "100%",
                                backgroundImage: `url(${data?.profile})`,
                                backgroundSize: "cover",
                                backgroundPosition: "top center",
                                backgroundRepeat: "no-repeat",
                              }}
                            />

                            {/* <div className="team-overlay-details top-left-overlay typo-dark text-center">
                            <div className="social-icons">
                              <a href="#">
                                <span className="ti-facebook"></span>
                              </a>
                              <a href="#">
                                <span className="ti-twitter"></span>
                              </a>
                              <a href="#">
                                <span className="ti-instagram"></span>
                              </a>
                              <a href="#">
                                <span className="ti-linkedin"></span>
                              </a>
                            </div>
                          </div> */}
                          </Link>
                        </div>
                        <div className="team-details text-center pad-20">
                          {/* <div className="team-designation theme-color">
                            <p className="mb-0">{data?.speciality}</p>
                          </div> */}
                          <div className="team-name">
                            <h3 className="mb-0">
                              <Link
                                to={`/doctor/${data?.uid}/${createSlug(
                                  data?.full_name
                                )}`}
                                className="client-name"
                              >
                                {data?.full_name}
                              </Link>
                            </h3>
                          </div>
                          <div className="team-designation theme-color">
                            <p className="mb-0">{data?.department}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default SecondMeetDoctors;
