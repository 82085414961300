import React, { useState, useEffect } from "react";
import { IoMdHeartEmpty, IoMdHeart } from "react-icons/io";
import { FaAngleDoubleRight } from "react-icons/fa";
import FruitsDetail from "./FruitsDetail";
import FruitsList from "./FruitsList";
import FruitFilter from "./FruitFilter";
import { SendPostRequest } from "../../http";

function Fruits({
  fdata,
  fruitId,
  setFruitId,
  fetch,
  setSelectedTab,
  selectedTab,
  isLoading,
  setIsLoading,
}) {
  const [checkedButtons, setCheckedButtons] = useState(
    Array(fdata.length).fill(false)
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handleToggle = (index) => {
    setCheckedButtons((prevCheckedButtons) => {
      const newCheckedButtons = [...prevCheckedButtons];
      newCheckedButtons[index] = !newCheckedButtons[index];
      return newCheckedButtons;
    });
  };
  const [subCategories, setSubCategories] = useState([]);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [fruits, setFruits] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [responseData, setResponseData] = useState();

  // const [fruitId, setFruitId] = useState();

  const [secondCategory, setSecondCategory] = useState("");

  const nutrientCategories = [
    "Please Select",
    "Carbohydrates",
    "Energy",
    "Fats",
    "Minerals",
    "Others",
    "Protein",
    "Vitamins",
  ];

  useEffect(() => {
    if (secondCategory === "Please Select") {
      setSubCategories([]);
      return;
    }

    const fetchData = async () => {
      try {
        const response = await SendPostRequest("home/diet_sub_categories", {
          host: "drburhan.clinta.biz",
          category: secondCategory,
        });
        if (response.status === "success") {
          setSubCategories(response.data || []);
        } else {
          console.error("Error fetching data:", response.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
      }
    };

    fetchData();
  }, [secondCategory, responseData]);

  const handleSelectChange = (e) => {
    setSecondCategory(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitLoading(true);
    try {
      const response = await SendPostRequest(
        "home/diet_sub_categories_filter",
        {
          host: "drburhan.clinta.biz",
          nutrient_category : selectedTab,
          category: secondCategory,
          sub_cat: selectedSubCategory,
        }
      );
      setResponseData(response.data);
      setIsSubmitLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
    }
  };

  const handleFruitClick = (UID) => {
    setFruitId(UID);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="fruits">
      {!fruitId && (
        <>
          <h3>Diet Facts</h3>

          {/* Filteration search  */}
          <div className="search-sec">
            <h4>SEARCH BY NUTRITION FACTS</h4>
            <form className="row" onSubmit={handleSubmit}>
              <div className="col-lg-3 col-md-12 col-sm-12 mb-3">
                <label htmlFor="" className="">
                  Food Category:
                </label>
                <select
                  name=""
                  id=""
                  value={selectedTab}
                  onChange={(e) => {
                    setSelectedTab(e.target.value);
                  }}
                >
                  <option value="">All</option>
                  <option value="fruits">Fruits</option>
                  <option value="vegetables">Vegetable</option>
                  <option value="pulses-grains">Pulses & Grains</option>
                  <option value="dry-fruites">Dry Fruits % Edible Seeds</option>
                  <option value="miscellaneous">Miscellaneous</option>
                </select>
              </div>

              <div className="col-lg-3 col-md-12 col-sm-12 mb-3">
                <label htmlFor="nutrientCategory">Nutrient Category:</label>
                <select
                  id="nutrientCategory"
                  value={secondCategory}
                  onChange={handleSelectChange}
                  required
                >
                  {nutrientCategories.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 mb-3">
                <label htmlFor="subCategory">Item:</label>
                <select
                  id="subCategory"
                  disabled={isLoading}
                  onChange={(e) => setSelectedSubCategory(e.target.value)}
                  required
                >
                  <option value="">First Select Categoy</option>
                  {isLoading ? (
                    <option value="">Loading...</option>
                  ) : secondCategory === "Please Select" ? (
                    <></>
                  ) : subCategories?.length === 0 ? (
                    <></>
                  ) : (
                    subCategories.map((subCategory) => (
                      <option key={subCategory.UID} value={subCategory.UID}>
                        {subCategory.SubCategory}
                      </option>
                    ))
                  )}
                </select>
              </div>

              <div className="col-lg-2 col-md-12 col-sm-12 ">
                <button type="submit">Submit</button>
              </div>
            </form>
          </div>
        </>
      )}

      {/* Fruit Filter  Detail*/}

      {!fruitId && responseData?.basic_detail && (
        <div className="Nutrient-details">
          <h5>
            {responseData?.basic_detail.cat}
            <FaAngleDoubleRight className="mx-2" />
            {responseData?.basic_detail.sub_cat}
          </h5>
          <hr />
          <div className="Nutrient-detail">
            <div className="units">
              <p>
                <span>Recomanded Daily Allowance (RDA): </span>
                {responseData?.basic_detail.rda}
                {responseData?.basic_detail.unit}
              </p>
              <p>
                <span>Tolerable upper intake levels (UL): </span>
                {responseData?.basic_detail.ul}
                {responseData?.basic_detail.unit}
              </p>
            </div>

            <div
              className="Nutrient-desc"
              dangerouslySetInnerHTML={{
                __html: responseData?.basic_detail.description,
              }}
            />
          </div>
        </div>
      )}

      {
        <div className="row  portfolio-wrap portfolio-classic portfolio-modern no-gutter mt-5">
          {
            // Fruit Filter
            !fruitId && (
              <FruitFilter
                isSubmitLoading={isSubmitLoading}
                responseData={responseData}
                fdata={fdata}
                handleFruitClick={handleFruitClick}
              />
            )
          }

          {/* Fruit List  */}

          {!fruitId && !responseData && (
            <FruitsList
              fetch={fetch}
              fdata={fdata}
              isLoading={isLoading}
              handleFruitClick={handleFruitClick}
            />
          )}

          {/* FruitsDetail */}

          {fruitId && <FruitsDetail fruitId={fruitId} />}
        </div>
      }
    </div>
  );
}

export default Fruits;
