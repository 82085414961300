import React from "react";
import { useSelector } from "react-redux";

function Topbar() {
  const sitedata = useSelector((store) => store.global.loginData);
  return (
    <div className="topbar full-view-switch bg-theme semi-dark">
      <div className="basic-container clearfix typo-white">
        <ul className="nav topbar-items pull-left">
          <li className="nav-item">
            <ul className="nav header-info">
              <div className="header-address">
                <span className="ti-headphone-alt"></span>
                <a
                  href={`https://wa.me/${sitedata?.site_contact}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {sitedata?.site_contact}
                </a>
                <span className="ti-email ms-3"></span>
                <a href={`mailto:${sitedata?.site_email}`}>
                  {sitedata?.site_email}
                </a>
              </div>
            </ul>
          </li>
        </ul>
        <ul className="nav topbar-items pull-right">
          <li className="nav-item">
            <div className="social-icons typo-white">
              {sitedata?.site_extra?.facebook_link && (
                <a
                  href={sitedata?.site_extra?.facebook_link}
                  className="social-fb"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="ti-facebook"></span>
                </a>
              )}
              {sitedata?.site_extra?.twitter_link && (
                <a
                  href={sitedata?.site_extra?.twitter_link}
                  className="social-twitter"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="ti-twitter"></span>
                </a>
              )}
              {sitedata?.site_extra?.instagram_link && (
                <a
                  href={sitedata?.site_extra?.instagram_link}
                  className="social-instagram"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="ti-instagram"></span>
                </a>
              )}
              {sitedata?.site_extra?.linkedin_link && (
                <a
                  href={sitedata?.site_extra?.linkedin_link}
                  className="social-instagram"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="ti-linkedin"></span>
                </a>
              )}
              {sitedata?.site_extra?.tiktok_link && (
                <a
                  href={sitedata?.site_extra?.tiktok_link}
                  className="social-tiktok"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="fab fa-tiktok"></span>
                </a>
              )}
              {sitedata?.site_extra?.youtube_link && (
                <a
                  href={sitedata?.site_extra?.youtube_link}
                  className="social-youtube"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="fab fa-youtube"></span>
                </a>
              )}
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Topbar;
