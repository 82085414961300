import React, { useState, useEffect, useRef } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { SendPostRequest } from "../http";
import Spinner from "./Spinner";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
export default function Reviews() {
  const [reviewsData, setReviewsData] = useState([]);
  console.log("reviewsData",reviewsData)
  const [expandedReviews, setExpandedReviews] = useState([]);
  const [reviewHeights, setReviewHeights] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [maxHeight, setMaxHeight] = useState(450);
  const reviewRef = useRef([]);
  const [hasError, setHasError] = useState(false);


  const sitedata = useSelector((store) => store.global.loginData);

  function capitalize(str) {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  }

  useEffect(() => {
    const title = sitedata?.site_title || "";
    document.title = capitalize(title) + " :: Reviews - ClinTa";
    window.scrollTo(0, 1);
    getReviewsData();
  }, []);

  

  useEffect(() => {
    if (reviewRef.current.length > 0) {
      const heights = reviewRef.current.map((ref) => ref.clientHeight);
      setReviewHeights(heights);
    }
  }, [reviewsData]);

  // const getReviewsData = async () => {
  //   try {
  //     setIsLoading(true);
  //     const response = await SendPostRequest("reviews");
  //     setReviewsData(response?.data);
  //   } catch (error) {
  //     console.error("Error fetching reviews:", error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };


  const getReviewsData = async () => {
    if (!sitedata?.site_id) {
      console.warn("No site_id found, skipping reviews fetch.");
      return;
    }
  
    try {
      setIsLoading(true);
      const response = await SendPostRequest("reviews", { site_id: sitedata?.site_id });
      if (response?.status === "success") {
        setReviewsData(response?.data);
      } else {
        setReviewsData([]);
        toast.error("Failed to fetch reviews");
      }
    } catch (error) {
      console.error("Error fetching reviews:", error);
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };
  
  const [expandedIndex, setExpandedIndex] = useState(null);
  const toggleExpandReview = (index) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  const formRef = useRef(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [addReviewsData, setAddReviewsData] = useState({
    full_name: "",
    upload_image: null,
    remarks: "",
  });

  const handleFileChange = (e) => {
    let name = e.target.name;
    let file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result.split(",")[1];
      setAddReviewsData((prevData) => ({
        ...prevData,
        [name]: base64String,
      }));
    };
    reader.readAsDataURL(file);
  };

  const handlePostData = (e) => {
    let { value, name } = e.target;
    setAddReviewsData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    formRef.current.reset();
    if (!addReviewsData.full_name) {
      toast.error("InComplete Form");
      return;
    } else if (!addReviewsData.remarks) {
      toast.error("InComplete Form");
      return;
    }

    try {
      setIsSubmitting(true);
      const response = await SendPostRequest("reviews/add", addReviewsData);
      setAddReviewsData({
        full_name: "",
        upload_image: null,
        remarks: "",
      });

      if (response.status === "success") {
        toast.success(`${response.message}`);
      } else {
        toast.error(`Error: ${response.message}`);
        console.error("Error fetching complaints data:", response.message);
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`);
      console.error("Error fetching complaints data:", error);
    } finally {
      setIsSubmitting(false);
    }
  };
  const [show, setShow] = useState(false);

  const [reviewIndex, setReviewIndex] = useState();

  const handleClose = () => setShow(false);
  const handleShow = (index) => {
    setReviewIndex(index);
    setShow(true);
  };

  const [backgroundImage, setBackgroundImage] = useState("");
  useEffect(() => {
    let themeColor;
    if (sitedata) {
      themeColor = sitedata?.theme_color?.split(".css").join("");
    } else {
    }

    setBackgroundImage(`/assets/img/doctors/banner_bg_${themeColor}.svg`);
  }, [sitedata?.theme_color]);
  return (
    <>
      <Header />
      <section
        className="cs_banner cs_style_5 cs_bg_filed"
        // style={{
        //   backgroundImage:
        //     sitedata.site_type === "hospitals"
        //       ? "url('/assets/img/doctors/banner_bg.svg')"
        //       : "url('/assets/img/doctors/banner_bg_gold.svg')",
        // }}

        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        {/* <div className="cs_banner_img">
          <img src="/assets/img/Reviews/review_blue.png" alt="Banner" />
        </div> */}
        <div className="container">
          <div className="cs_banner_text">
            <h2 className="cs_banner_title cs_fs_72">Reviews</h2>
          </div>
        </div>
      </section>
      <div className="cs_height_42 cs_height_xl_25"></div>

      <div className="cs_height_42 cs_height_xl_25"></div>

      {reviewsData?.length === 0 ? (
        <h2 style={{ textAlign: "center" }}>
          Congratulations on being the first to review! Your feedback means a
          lot to us.
        </h2>
      ) : (
        <h2 style={{ textAlign: "center" }}>WHAT PATIENTS ARE SAYING</h2>
      )}
      <section className="review-section">
        <div className="container">
        <div className="row">
  {isLoading ? (
    <div className="d-flex align-items-center justify-content-center w-100" style={{ height: "200px" }}>
      <Spinner />
    </div>
  ) : hasError || reviewsData?.length === 0 ? (
    <div className="d-flex align-items-center justify-content-center w-100" style={{ height: "200px" }}>
      <h4>No Data Found</h4>
    </div>
  ) : (
    reviewsData.map((reviews, index) => (
      <div key={index} className="col-lg-4 col-md-6 col-12 my-3">
        <div className="cs_slide">
          <div
            className={`cs_testimonial review-card-hover cs_style_3 cs_radius_20 cs_white_bg text-center pb-0 ${
              expandedIndex === index ? "expanded" : ""
            }`}
          >
            <div
              className="cs_testimonial_img"
              style={{
                backgroundImage: `url(${
                  reviews?.profile_image !== "https://api.dright.net/"
                    ? reviews.profile_image
                    : "/assets/img/default_user.jpg"
                })`,
                backgroundPosition: "top center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                borderRadius: "50%",
              }}
            ></div>
            <h2 className="cs_testimonial_avatar cs_fs_24 cs_semibold">
              {reviews?.full_name}
            </h2>
            <div className="cs_testimonial_text cs_fs_20 mb-3 d-inline">
              {expandedIndex === index
                ? reviews?.detail
                : truncateText(reviews?.detail, 100)}
            </div>
            {reviews?.detail?.length > 100 && (
              <div
                onClick={() => handleShow(index)}
                className="service-read-more"
              >
                See More
              </div>
            )}
          </div>
        </div>
      </div>
    ))
  )}

  <Modal show={show} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title className="review-modal-title">
        {reviewsData[reviewIndex]?.full_name}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>{reviewsData[reviewIndex]?.detail}</Modal.Body>
    <Modal.Footer>
      <Button
        className="review-modal-button"
        variant="secondary"
        onClick={handleClose}
      >
        Close
      </Button>
    </Modal.Footer>
  </Modal>
</div>

        </div>
      </section>
      <div className="cs_height_42 cs_height_xl_25"></div>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 ">
              <form
                ref={formRef}
                onSubmit={handleSubmit}
                className="cs_contact_form  cs_white_bg "
              >
                <h3>Add Review</h3>
                <div className="row">
                  <div className="col-lg-6 col-md-12 col-12">
                    <div className="col-lg-12 col-md-12 col-12 mb-3">
                      <label className="cs_input_label cs_heading_color">
                        Full Name <span className="asterisk">*</span>
                      </label>

                      <input
                        type="text"
                        className="cs_form_field"
                        name="full_name"
                        value={addReviewsData.full_name || ""}
                        onChange={handlePostData}
                        placeholder="Enter full name"
                        required
                      />
                      {/* <div className="cs_height_42 cs_height_xl_25"></div> */}
                    </div>
                    <div className="col-lg-12 col-md-12 col-12">
                      <label className="cs_input_label cs_heading_color">
                        Image
                      </label>
                      <input
                        type="file"
                        className="cs_form_field"
                        placeholder=""
                        name="upload_image"
                        onChange={handleFileChange}
                        accept="image/*"
                      />
                      <div className="cs_height_42 cs_height_xl_25"></div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-12">
                    <div className="col-lg-12 col-md-12 col-12">
                      <label className="cs_input_label cs_heading_color">
                        Remarks <span className="asterisk">*</span>
                      </label>
                      <textarea
                        cols="30"
                        rows="6"
                        className="cs_form_field review-textarea"
                        placeholder="Write something..."
                        name="remarks"
                        value={addReviewsData.remarks || ""}
                        onChange={handlePostData}
                      ></textarea>
                      <div className="cs_height_42 cs_height_xl_25"></div>
                    </div>
                  </div>

                  <div className="col-lg-12">
                    <div className="cs_height_18"></div>
                    <button
                      onClick={handleSubmit}
                      className="cs_btn cs_style_1"
                    >
                      <span>Submit</span>
                      <i>
                        <img
                          src="/assets/img/icons/arrow_white.svg"
                          alt="Icon"
                        />
                        <img
                          src="/assets/img/icons/arrow_white.svg"
                          alt="Icon"
                        />
                      </i>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <div className="cs_height_42 cs_height_xl_25"></div>
      <Footer />
    </>
  );
}
