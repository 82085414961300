import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { SendPostRequest } from "../../http";
import Spinner from "../Spinner";
import { useSelector } from "react-redux";
import HeaderWrapper from "../Header/HeaderWrapper";
import FooterWrapper from "../Footer/FooterWrapper";

function FacilitiesDetail() {
  const sitedata = useSelector((store) => store.global.loginData);
  const { slug } = useParams();
  const [facilitiesDetail, setFacilitiesDetail] = useState({});
  const [facilitiesList, setFacilitiesList] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    document.title = "Facilites Detail";
    window.scrollTo(0, 0);
    getFacilitiesDetail();
  }, [slug]);

  const getFacilitiesDetail = async () => {
    try {
      setLoading(true);
      const response = await SendPostRequest("facilities-detail", {
        slug: slug,
      });

      if (response.status === "success") {
        setFacilitiesList(response?.data?.list);
        setFacilitiesDetail(response?.data?.detail);
      } else {
        console.warn("No service detail found in the response.");
      }
    } catch (error) {
      console.error("Error fetching services detail:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="page-wrapper-inner">
      <div className="page-wrapper-inner">
       <HeaderWrapper />
      <div className="page-title-wrap typo-white">
        <div
          className="page-title-wrap-inner section-bg-img"
          style={{
            backgroundImage: "url(/assets/images/bg/page-title-bg.jpg)",
          }}
        >
          <span className="black-overlay"></span>
          <div className="container">
            <div className="row text-left">
              <div className="col-md-12">
                <div className="page-title-inner">
                  <h1 className="page-title mb-0">
                    {facilitiesDetail?.facility_title || "Facility Detail"}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section id="blog-single" className="blog-single">
        <div className="container">
          <div className="blog-main-wrap blog-list">
            <div className="row">
              <div className="col-lg-8">
                {loading ? (
                  <div className="d-flex align-items-center justify-content-center">
                    <Spinner />
                  </div>
                ) : facilitiesDetail && facilitiesDetail?.facility_image ? (
                  <>
                    <div className="blog-wrap mb-4 pb-3">
                      <div className="blog-info-wrap">
                        <div className="blog-single-img mb-4 pb-2">
                          <img
                            src={facilitiesDetail?.facility_image}
                            width="1280"
                            height="852"
                            className="img-fluid b-radius-8"
                            alt="blog-img"
                          />
                        </div>
                        <div className="blog-content pb-4 mb-2">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: facilitiesDetail?.facility_description,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <h5 className="text-center">No Data Found</h5>
                )}
              </div>

              <div className="col-lg-4 ps-5 px-sm-15">
                <div className="sidebar right-sidebar ">
                  <div className="widget category-widget services_cs_scroll_sidebar">
                    <div className="widget-title">
                      <h3 className="title">Other Facilities</h3>
                    </div>
                    <ul className="category-list">
                      {facilitiesList && facilitiesList.length > 0 ? (
                        facilitiesList.map((list, index) => (
                          <li key={index}>
                            <Link
                              to={`/facility/${list?.facility_slug
                                .replaceAll(" ", "-")
                                .replaceAll("/", "-")}`}
                            >
                              {list?.facility_title}
                            </Link>
                          </li>
                        ))
                      ) : (
                        <li>No other facilities found</li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FooterWrapper/>
      </div>
    </div>
  );
}

export default FacilitiesDetail;
