import React, { useState } from "react";
import { Navbar, Nav } from "react-bootstrap";

function RecentTabs({ onTabSelect }) {
  const [selectedTab, setSelectedTab] = useState("Items");
  const handleNavItemClicked = (heading) => {
    onTabSelect(heading);
    // setOpenDropdown(null);
    setSelectedTab(heading); // Close the dropdown when a tab is clicked
  };
  return (
    <div className="profileTabs">
      <Navbar bg="" expand="lg" variant="dark">
        <Navbar.Toggle aria-controls="main_nav" />
        <Navbar.Collapse id="main_nav">
          <Nav className="mr-auto p-3">
            <Nav.Link
              className={selectedTab === "Items" ? "active" : ""}
              onClick={() => handleNavItemClicked("Items")}
            >
              Diet Item
            </Nav.Link>
            <Nav.Link
              className={selectedTab === "Totky" ? "active" : ""}
              onClick={() => handleNavItemClicked("Totky")}
            >
              Totkey
            </Nav.Link>
            <Nav.Link
              className={selectedTab === "BasicAppointment" ? "active" : ""}
              onClick={() => handleNavItemClicked("BasicAppointment")}
            >
              Today Basic Appointment
            </Nav.Link>
            {/* <Nav.Link
              className={selectedTab === "EAppointment" ? "active" : ""}
              onClick={() => handleNavItemClicked("EAppointment")}
            >
              Today E-Health Appointment
            </Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

export default RecentTabs;
