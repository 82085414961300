import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function LightDarkDoctorNav() {
  const [isSticky, setIsSticky] = useState(false);
  const patientLogin = useSelector((state) => state.patient.patientLogin);
  const sitedata = useSelector((store) => store.global.loginData);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="sticky-outer full-dark light_dark_navbar">
      <div
        className={`sticky-head ${
          isSticky ? "light_dark_navbar header-sticky" : ""
        }`}
      >
        <nav className="navbar">
          <div className="basic-container clearfix">
            <div className="navbar-inner">
              <ul className="nav navbar-items pull-left">
                <li className="list-item">
                  <Link
                    to={"/"}
                    className="logo-general d-flex align-items-center gap-1"
                  >
                    <img
                      src={
                        sitedata?.site_logo
                          ? `${sitedata?.site_logo}`
                          : "/assets/img/hospital-static.jpg"
                      }
                      alt="Logo"
                      style={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "10px",
                        marginTop: "5px",
                      }}
                    />
                    <h5
                      className={`mb-0 ${
                        isSticky ? "light_dark_navbar_logo" : ""
                      }`}
                    >
                      {sitedata.site_type !== "hospitals" &&
                      sitedata?.site_title?.length > 0 ? (
                        <span>{"Dr. " + sitedata?.site_title}</span>
                      ) : (
                        sitedata?.site_title
                      )}
                    </h5>
                  </Link>
                </li>
              </ul>
              <ul className="nav navbar-items pull-right">
                <li className="list-item">
                  <ul
                    className={`nav navbar-main theme-main-menu ${
                      isSticky ? "light_dark_navbar_links" : ""
                    }`}
                  >
                    <li className="menu-item">
                      <Link to={"/"}>Home</Link>
                    </li>
                    <li className="menu-item">
                      <Link to={"/about"}>About Us</Link>
                    </li>
                    <li class="dropdown menu-item dropdown-sub">
                      <a>Patient Care</a>
                      <ul class="dropdown-menu">
                        <li>
                          <Link to={"/hospital-clinic/0"}>Hospital/Clinic</Link>
                          <Link to={"/specialities"}>Specialties</Link>
                        </li>
                      </ul>
                    </li>
                    <li class="dropdown menu-item dropdown-sub">
                      <a>Media</a>
                      <ul class="dropdown-menu">
                        <li>
                          {sitedata?.permissions?.review !== "hide" && (
                            <Link to={"/reviews"}>Reviews</Link>
                          )}
                          <Link to={"/gallery"}>Gallery</Link>
                        </li>
                      </ul>
                    </li>
                    <li className="menu-item">
                      <Link to={"/contact"}>Contact Us</Link>
                    </li>
                  </ul>
                </li>
                <li
                  className={`list-item Login-btn-new-theme ${
                    isSticky ? "light_dark_navbar_links" : ""
                  }`}
                >
                  {patientLogin ? (
                    <Link to="/patient">My Account</Link>
                  ) : (
                    <Link to="/login">Login</Link>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default LightDarkDoctorNav;
