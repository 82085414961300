export const today = new Date().toISOString().split("T")[0];

export const formatDate = (inputDate) => {
  return new Date(inputDate).toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
};

export const formatTime = (time) => {
  if (!time) return "";
  return new Date(`2000-01-01T${time}`).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const getPreviousDate = (days) => {
  const today = new Date();
  const previousDate = new Date();
  return previousDate.setDate(today.getDate() - days);
};


export const  limitParagraphByCharLimit = (paragraph, charLimit) => {
  if (!paragraph || charLimit <= 0) {
    return "";
  }
  let truncated = paragraph.slice(0, charLimit);
  const lastSpacePos = truncated.lastIndexOf(" ");
  if (lastSpacePos === -1) {
    return truncated;
  }
  return truncated.slice(0, lastSpacePos);
}
