import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { capitalize } from "@mui/material";
import { SendPostRequest } from "../../http";
import Spinner from "../Spinner";
import HeaderWrapper from "../Header/HeaderWrapper";
import FooterWrapper from "../Footer/FooterWrapper";
import { limitParagraphByCharLimit } from "../../Helper/Helper";

function ServicesListing() {
  const sitedata = useSelector((store) => store.global.loginData);
  const [isLoading, setIsLoading] = useState(true);
  const [isData, setIsdata] = useState(false);
  const [servicesData, setServicesData] = useState([]);

  useEffect(() => {
    const title = sitedata?.site_title || "";
    document.title = capitalize(title) + " :: Services - ClinTa";
    window.scrollTo(0, 0);
    getServicesData();
  }, []);

  const getServicesData = async () => {
    const response = await SendPostRequest("services/list");
    setIsLoading(false);

    if (!response?.data || response?.data?.length < 1) {
      setIsdata(true);
    } else {
      setServicesData(response.data);
    }
  };

  return (
    <div className="page-wrapper-inner">
      <div className="page-wrapper-inner">
        <HeaderWrapper />
        <div className="page-title-wrap typo-white">
          <div
            className="page-title-wrap-inner section-bg-img"
            style={{
              backgroundImage: "url(/assets/images/bg/page-title-bg.jpg)",
            }}
          >
            <span className="black-overlay"></span>
            <div className="container">
              <div className="row text-left">
                <div className="col-md-12">
                  <div className="page-title-inner">
                    <h1 className="page-title mb-0">Our Speciality</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row mt-5">
            <div className="offset-md-2 col-md-8">
              <div className="title-wrap text-center">
                <div className="section-title margin-bottom-60">
                  <h2 className="section-title mb-0 text-uppercase">
                    Provides Our{" "}
                    <span className="theme-color">Best Specialities</span>
                  </h2>
                  <span className="section-border-bottom center"></span>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            {isLoading ? (
              <div className="col-12 text-center">
                <Spinner />
              </div>
            ) : isData ? (
              <div className="col-12 text-center mb-4">
                <h4>No services found</h4>
              </div>
            ) : (
              servicesData.map((service, index) => (
                <div className="col-lg-4 item" key={index}>
                  <div className="service-box-wrap service-box-style-2 mb-5">
                    <div className="service-box-inner">
                      <div className="service-thumb">
                        <img
                          className="img-fluid squared w-100 third-fservices-image"
                          src={service?.service_image_url}
                          alt={service?.service_title}
                        />
                      </div>
                      <div className="service-main-content">
                        <h3 className="mb-0 service-title">
                          {service?.service_title}
                        </h3>
                        <div className="service-link">
                          <Link
                            to={`/speciality/${
                              service?.uid
                            }/${service?.service_title
                              .replaceAll(" ", "-")
                              .replaceAll("/", "-")}`}
                            rel="noopener noreferrer"
                            className="link"
                          >
                            <i className="ti-arrow-top-right"></i>
                          </Link>
                        </div>
                      </div>
                      <div className="service-overlay-content">
                        <div className="service-meta">
                          <h3 className="mb-2 service-title">
                            <a href={service?.link} className="">
                              {service?.service_title}
                            </a>
                          </h3>
                          <div className="service-link">
                            <Link
                              to={`/speciality/${
                                service?.uid
                              }/${service?.service_title
                                .replaceAll(" ", "-")
                                .replaceAll("/", "-")}`}
                              rel="noopener noreferrer"
                              className="link"
                            >
                              <i className="ti-arrow-top-right"></i>
                            </Link>
                          </div>
                        </div>
                        <div
                          className="service-desc mb-4"
                          dangerouslySetInnerHTML={{
                            __html:
                              limitParagraphByCharLimit(
                                service?.service_description,
                                80
                              ) + "...",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
        <FooterWrapper />
      </div>
    </div>
  );
}

export default ServicesListing;
