import React, { useState, useEffect, useRef } from "react";
import "./ThirdMainSlider.css";
import { SendPostRequest } from "../../http";
import { useSelector } from "react-redux";

function ThirdMainSlider() {
  const [index, setIndex] = useState(0);
  const [direction, setDirection] = useState(null);
  const sliderRef = useRef(null);
  const [homeBanners, setHomeBanners] = useState([]);
  const sitedata = useSelector((store) => store.global.loginData);

  useEffect(() => {
    brandingData();
  }, []);

  const brandingData = async () => {
    try {
      const siteTheme = sitedata?.options?.theme;
      const response = await SendPostRequest("branding/banners", {
        theme: siteTheme,
      });
      if (Array.isArray(response?.data)) {
        setHomeBanners(response.data);
      } else {
        setHomeBanners([]);
      }
    } catch (error) {
      console.error("Error fetching banners:", error);
      setHomeBanners([]);
    }
  };

  const moveSlider = (direction) => {
    if (homeBanners.length === 0) return;

    setDirection(direction);
    setIndex((prevIndex) => {
      return direction === "next"
        ? (prevIndex + 1) % homeBanners.length
        : (prevIndex - 1 + homeBanners.length) % homeBanners.length;
    });
  };

  useEffect(() => {
    const handleAnimationEnd = () => {
      if (sliderRef.current) {
        sliderRef.current.classList.remove("next", "prev");
        setDirection(null);
      }
    };

    const sliderElement = sliderRef.current;
    if (sliderElement) {
      sliderElement.addEventListener("animationend", handleAnimationEnd);
    }

    return () => {
      if (sliderElement) {
        sliderElement.removeEventListener("animationend", handleAnimationEnd);
      }
    };
  }, []);

  useEffect(() => {
    if (sliderRef.current && direction) {
      sliderRef.current.classList.add(direction);
    }
  }, [index, direction]);

  const getReorderedImages = (offset = 0) => {
    if (homeBanners.length === 0) return [];

    const reorderedImages = [...homeBanners];
    const currentIndex = (index + offset) % homeBanners.length;
    return reorderedImages
      .slice(currentIndex)
      .concat(reorderedImages.slice(0, currentIndex));
  };

  return (
    <div className="slider" ref={sliderRef}>
      <div className="list">
        {homeBanners.map((data, i) => (
          <div
            className={`item ${i === index ? "active" : ""}`}
            key={data?.banner_id || i}
          >
            <div className="shadow"></div>
            <div
              style={{
                backgroundImage: `url(${data?.banner_image})`,
                width: "100%",
                height: "100%",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
            <div className="content">
              <div className="type">{data?.banner_title}</div>
              <div className="description">{data?.banner_description}</div>
            </div>
          </div>
        ))}
      </div>

      {homeBanners.length > 1 && (
        <div className="thumbnail">
          {getReorderedImages(1)
            .slice(0, 2)
            .map((image, i) => (
              <div className={`item ${i === 0 ? "active" : ""}`} key={i}>
                <img src={`${image?.banner_image}`} alt="" />
              </div>
            ))}
        </div>
      )}

      {homeBanners.length > 1 && (
        <div className="nextPrevArrows">
          <button className="prev" onClick={() => moveSlider("prev")}>
            &lt;
          </button>
          <button className="next" onClick={() => moveSlider("next")}>
            &gt;
          </button>
        </div>
      )}
    </div>
  );
}

export default ThirdMainSlider;
